@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.btnWhite {
  width: 240px;
  height: 53px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #25282e;
  cursor: pointer;

  &:hover {
    background: #fff200;
    border: 0;
  }
}

.btnYellow {
  width: 240px;
  height: 53px;
  background: #fff200;
  font-family: "Archivo";
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #25282e;
  cursor: pointer;
}

.smallBtn {
  width: 120px !important;
}

.header {
  height: 76px;
  padding: 16px 42px;
  background-color: #fff;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  top: 0;
  border-bottom: 1.5px solid #e6e6e6;
}

.header .insideMenu {
  margin-left: 200px;
}

.header ul {
  margin-right: 20px;
  list-style: none;
  display: flex;
  align-items: center;

  .socialMediaIcons {
    display: none;
    svg {
      cursor: pointer;
    }
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 6px;
  }

  .dropdownContent a {
    color: #575757;
    padding: 10px 16px;
    text-decoration: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;

    & svg:last-child {
      display: none;
    }
  }

  .dropdownContent a:hover {
    background-color: rgb(233, 233, 233);
    color: #000;

    & svg:last-child {
      display: flex;
    }
    & svg:first-child {
      display: none;
    }
  }

  .dropdown:hover .dropdownContent {
    display: block;
  }
}

.header ul li {
  display: inline-block;
  margin-left: 42px;
}

.header ul li a,
.header ul li .followDropdown {
  font-family: "Archivo";
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: #25282e;
  text-decoration: none;
  cursor: pointer;
}

.header #navToggle {
  position: absolute;
  top: -100px;
}

.header .iconBurger {
  position: absolute;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}

.header .iconBurger .line {
  width: 30px;
  height: 5px;
  background-color: #25282e;
  margin: 5px;
  border-radius: 3px;
}

.container {
  position: relative;
  top: 76px;
}

.img-slider {
  position: relative;
  height: 772px;
  width: 100%;
  background: #25282e;
}

.img-slider .slide {
  z-index: 1;
  position: absolute;
  width: 100%;
  -webkit-clippath: circle(0% at 0 50%);
  clippath: circle(0% at 0 50%);
}

.img-slider .slide.active {
  -webkit-clippath: circle(150% at 0 50%);
  clippath: circle(150% at 0 50%);
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-transition-property: -webkit-clipPath;
  transition-property: -webkit-clipPath;
  transition-property: clipPath;
  transition-property: clipPath, -webkit-clipPath;
}

.img-slider .slide img {
  z-index: 1;
  height: 772px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.img-slider .navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 30px;
  z-index: 3;
}

.img-slider .titleField {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.img-slider .titleField p {
  font-weight: 400;
  font-size: 21px;
  margin-top: 16px;
  margin-bottom: 36px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.img-slider .titleField h2:first-child {
  font-weight: 700;
  font-size: 61px;
}

.img-slider .titleField h2:nth-child(2) {
  font-weight: 500;
  font-size: 61px;
  margin-top: 16px;
}

.img-slider .navigation .circle {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  margin: 13px;
  cursor: pointer;
}

.img-slider .navigation .circle.active {
  background-color: #fff200 !important;
}

.exhibitionsContainer {
  text-align: center;
  padding: 96px 2.8rem 74px 2.8rem;
}

.exhibitionsContainer h1 {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  text-align: center;
  color: #25282e;
  overflow-wrap: break-word;
}

.exhibitionsContainer h1.detailExhibition {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
}

.exhibitionsContainer .exhibitions {
  margin-top: 40px;
  margin-bottom: 72px;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  // justify-content: space-between;
  column-count: 3;
}

.exhibitionsContainer .exhibitionCard {
  height: 410px;
  width: 420px;
  text-align: start;
  // margin: 0 30px;
  margin: 0 auto 40px auto;
  cursor: pointer;
}

.exhibitionsContainer .exhibitionCard .image {
  height: 280px;
  width: 416px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.exhibitionsContainer .exhibitionCard .image img {
  height: 280px;
  width: 100%;
  -o-object-fit: none;
  object-fit: none;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.exhibitionsContainer .exhibitionCard p {
  font-family: "Lora";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
  margin-top: 16px;
  margin-bottom: 8px;
  overflow-wrap: break-word;
}

.exhibitionsContainer.artistDetail .exhibitionCard .artistName {
  font-family: "Lora";
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  color: #25282e;
  margin-top: 16px;
  margin-bottom: 4px;
  overflow-wrap: break-word;
}
.exhibitionsContainer.artistDetail .exhibitionCard .workName {
  font-family: "Lora";
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  color: #25282e;
  margin-top: 0;
  margin-bottom: 8px;
  overflow-wrap: break-word;
}

.exhibitionsContainer.artistDetail .exhibitionCard .company {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #25282e;
  overflow-wrap: break-word;
  margin: 16px 0 4px 0;
}
.exhibitionsContainer .exhibitionCard .date {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #888888;
  overflow-wrap: break-word;
}
.exhibitionsContainer.artistDetail .exhibitionCard .date {
  font-size: 16px;
  line-height: 20px;
}

.exhibitionsContainer .exhibitionCard p.date {
  margin: 4px 0;
  font-family: "Archivo";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #888888;
}

.exhibitionsContainer .exhibitionCard .readMore {
  display: none;
  font-family: "Archivo";
  font-weight: 500;
  font-size: 17.0007px;
  line-height: 26px;
  text-decoration: underline;
  color: #25282e;
  cursor: pointer;
}

.exhibitionsContainer .exhibitionCard:hover .image {
  opacity: 0.4;
  height: 220px;
}

.exhibitionsContainer .exhibitionCard:hover .image img {
  height: 220px;
}

.exhibitionsContainer .exhibitionCard:hover .readMore {
  display: block;
}

.newsContainer {
  width: 100%;
  height: 1034px;
  background-color: #25282e;
}
.newsContainer.shop {
  width: 100%;
  height: 502px;
}

.artistsNews {
  align-items: center;
}

.newsContainer .newsHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 50px;
}

.newsContainer .newsHeader h2 {
  font-family: "Lora";
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #ffffff;
}
.newsContainer.artistDetail .newsHeader h2 {
  margin-left: 16px;
}

.newsContainer .newsHeader .newsRightSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.newsContainer .newsHeader .newsRightSide button {
  margin-right: 12px;
}

.newsContainer .newsHeader .newsRightSide .btnWhite {
  width: 262px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.newsContainer .newsHeader .newsRightSide .iconDiv {
  height: 53px;
  width: 53px;
  padding: 8.83333px;
  background: #333944;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 23px;
  margin: 0 4px;
  cursor: pointer;
}

.whiteIconDiv {
  color: #333944 !important;
  background: #fff !important;
}

// .newsContainer .news {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   padding: 0 26px 26px 26px;
//   background-color: #25282e;
//   overflow-x: auto;
//   scroll-behavior: smooth;

// }

.newsContainer .news::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.newsContainer .newsCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  margin: 0 24px;
  cursor: pointer;
  font-family: "Lora";
  min-width: 448px;
  max-width: 448px;
  height: 100%;
  gap: 24px;

  .image {
    background-color: #fff;
  }
}

.newsContainer .newsCard.shopCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  padding: 16px;
  margin: 0 24px;
  cursor: pointer;
  font-family: "Lora";
  // min-width: 448px;
  // max-width: 448px;
  height: 100%;
  gap: 24px;
  margin-right: 64px;
}

.newsContainer .newsCard img {
  max-height: 560px;
  width: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.newsContainer .newsCard.shopCard img {
  max-height: 265px;
  width: 196px;
  -o-object-fit: fill;
  object-fit: fill;
}
.newsContainer .newsCard.shopCard .rightSide {
  width: 266px !important;
}

.newsContainer .newsCard img.evenNumber {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.newsContainer .newsCard .title {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #ffffff;
  overflow-wrap: break-word;
}
.newsContainer .newsCard.shopCard .title {
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  width: 100%;
  color: #ffffff;
  overflow-wrap: break-word;
}

.newsContainer .newsCard .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  overflow-wrap: break-word;
  margin-top: 16px;
}

.newsContainer.shop .newsCard .description {
  width: 266px !important;
}

.newsContainer .newsCard.yellowBg:hover {
  background-color: #fff200;
}

.newsContainer .newsCard.yellowBg:hover .title,
.newsContainer .newsCard.yellowBg:hover .description {
  color: #25282e;
}

.newsContainer .newsCard.opacityBg:hover {
  img {
    opacity: 0.7;
  }
}

.detailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 70px;
  margin-top: 68px;
  margin-bottom: 88px;

  img {
    background-color: #f2f2f2;
    margin-right: 134px;
  }

  h2 {
    font-family: "Lora";
    font-weight: 700;
    font-size: 47px;
    line-height: 120%;
    color: #25282e;
    margin-bottom: 24px;
  }
  p {
    font-family: "Lora";
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #25282e;
  }
}

.publicationDetail {
  height: 560px;
  width: 100%;
  background: #25282e;
  padding: 37px 70px;

  div {
    display: flex;
    justify-content: space-between;
    h2 {
      width: 80px;
      height: 36px;
      font-family: "Lora";
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
    }
    button {
      width: 146px;
    }
  }

  ul {
    margin-top: 58px;
    list-style: none;
    column-count: 3;
    li {
      max-width: 400px;
      margin-bottom: 46px;
      margin-right: 33px;
    }
    .title {
      font-family: "Lora";
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
    }
    .description {
      font-family: "Lora";
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #fff200;
      margin-top: 16px;
    }
  }
}

.bannerContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 182px 0;
  padding: 0 40px;
}

.bannerContainer img {
  height: 450px;
  width: 648px;
  -o-object-fit: fill;
  object-fit: fill;
}
.bannerContainer.publicationBanner img {
  height: 600px;
  width: 648px;
  object-fit: cover;
  background: #f1f1f1;
  overflow: hidden;
}

.bannerContainer.publicationBanner {
  margin: 0 !important;
}

.bannerContainer .rightSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f1f1f1;
  width: 648px;
  height: 450px;
  padding: 0 80px;
}
.bannerContainer.publicationBanner .rightSide {
  background: #fff;
  align-items: flex-start;
  padding: 0 20px 0 120px;
}

.bannerContainer .rightSide .title {
  font-family: "Lora";
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #25282e;
  width: 70%;
}

.bannerContainer .rightSide .description {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #888888;
  margin: 24px 0 64px 0;
  width: 70%;
  overflow-wrap: break-word;
}

.bannerContainer.publicationBanner .rightSide .description {
  text-align: start;
  font-family: "Lora";
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #888888;
}
.bannerContainer.publicationBanner .rightSide .title {
  font-family: "Lora";
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
  text-align: start;
}

.fairsContainer {
  text-align: center;
}

.fairsContainer h2 {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
}

.fairsContainer .fairs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.fairsContainer .fairs::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.fairsContainer .fairs .fairCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 12px;
  height: 240px;
  width: 635px;
  border: 1px solid #e8e8e8;
}

.fairsContainer img {
  height: 100%;
  width: 305px;
  -o-object-fit: none;
  object-fit: none;
}

.fairsContainer .rightSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f1f1f1;
  width: 330px;
  height: 100%;
  padding: 0 30px;
  gap: 24px;
  text-align: start;
}

.fairsContainer .rightSide .title {
  font-family: "Lora";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #25282e;
  overflow-wrap: break-word;
}

.fairsContainer .rightSide .date {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #888888;
  overflow-wrap: break-word;
}

.fairsContainer .rightSide .details {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #25282e;
  text-decoration: none;
}

.artistsContainer {
  h1 {
    margin: 62px 0;
    text-align: center;
    font-family: "Lora";
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: #25282e;
  }
  .artists {
    display: flex;
    margin: 0 auto;
    width: 95%;
    .photosCol {
      .artistName {
        font-family: "Lora";
        font-weight: 700;
        font-size: 17px;
        line-height: 120%;
        color: #25282e;
        background-color: #fff;
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        padding: 10px;
      }
      width: 30%;
      margin: 0 12px;
      img {
        width: 100%;
        transition: all 0.5s ease;
      }
      .artistCard {
        position: relative;
        cursor: pointer;

        &:hover {
          img {
            opacity: 0.4;
          }

          .artistName {
            display: block;
          }
        }
      }
    }
  }
  .namesDiv {
    margin: 60px 0;
    column-count: 3;

    li {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 16px 0;
      text-align: center;

      a {
        font-family: "Lora";
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #25282e;
        text-decoration: none;
        img {
          width: 220px;
          margin-top: 16px;
          display: none;
        }

        &:hover {
          font-weight: 700;
          img {
            display: block;
          }
        }
      }
    }
  }
}

.subMenu {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 8px;

  li {
    display: inline-block;
    margin-left: 42px;

    a {
      font-family: "Lora";
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      /* TextColor/Gray */

      color: #888888;
      text-decoration: none;
    }
  }
}

.biography {
  font-family: "Lora";
  font-weight: 400;
  font-size: 16px;
  margin-top: 80px;
  // height: 522px;
  width: 100%;

  & > div {
    width: 73%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .bioText {
      flex: 3;
      h1 {
        font-weight: 700;
        font-size: 47px;
        line-height: 120%;
        color: #25282e;
        word-spacing: 100vw;
      }
      p {
        line-height: 150%;
        color: #888888;
        margin: 30px 0;
      }
      .downloadCV {
        line-height: 28px;
        text-decoration-line: underline;
        color: #888888;
      }
    }

    .pictureField {
      & > div {
        display: inline;
        float: right;
      }
      flex: 2;
      img {
        margin-bottom: 10px;
      }
      a {
        line-height: 16px;
        text-decoration-line: underline;
        color: #888888;
        display: block;
        margin-top: 6px;
      }
    }
  }
}

.selectedWorks {
  background: #f1f1f1;
  height: 910px;
  text-align: center;
  padding: 32px 2.8rem 0px 2.8rem;
  margin: 64px 0 0 0;

  h1 {
    font-family: "Lora";
    font-weight: 700;
    font-size: 27px;
    line-height: 140%;
    color: #25282e;
    margin-bottom: 10px;
  }

  .works {
    // display: flex;
    // align-items: center;
    // -ms-flex-wrap: wrap;
    // flex-wrap: wrap;
    // gap: 40px;
    column-count: 4;
    .workCard {
      width: 306px;
      height: 362.5px;
      // margin: 16px 30px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      margin: 0 auto;

      div {
        background-color: #000;
        height: 306px;
        img {
          width: 306px;
          height: 306px;
          transition: all 0.5s ease;
        }
      }

      p {
        position: absolute;
        width: 50%;
        left: 80px;
        font-family: "Lora";
        font-weight: 700;
        font-size: 27px;
        line-height: 140%;
        color: #fff;
        display: none;
      }

      &:hover {
        img {
          opacity: 0.5;
        }
        p {
          display: block;
        }
      }
    }
  }
  button {
    margin-top: 20px;
  }
}

.publicationsContainer {
  padding: 96px 2.8rem 74px 2.8rem;
  text-align: center;
  h2 {
    text-align: start;
    font-family: "Lora";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #25282e;
    margin-left: 23px;
    margin-bottom: 32px;
  }

  h1 {
    font-family: "Lora";
    font-weight: 700;
    font-size: 27px;
    line-height: 140%;
    color: #25282e;
    text-align: center;
    margin: 100px 0 40px 0;
  }
  .publications {
    display: flex;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px 0;
    text-align: start;

    .publicationCard {
      width: 306px;
      height: 503px;
      margin: 0 24px;
      cursor: pointer;

      .imgDiv {
        background-color: #f2f2f2;
        transition: all 0.4s ease-in;

        img {
          height: 324px;
        }
      }
      h3 {
        font-family: "Lora";
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        color: #25282e;
        margin-top: 19.13px;
        margin-bottom: 11.77px;
      }
      p {
        font-family: "Lora";
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #888888;
      }

      &:hover {
        .imgDiv {
          background-color: rgba(0, 0, 0, 0.3);
        }

        h3 {
          color: #888888;
        }
      }
    }
  }
}

.pressContainer {
  padding: 96px 4rem 74px 4rem;
  h2 {
    font-family: "Lora";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #25282e;
    margin-bottom: 36px;
  }

  .divButton {
    text-align: center;
  }

  ul {
    margin-bottom: 28px;
    li {
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      padding: 8px 0;
      .leftSide {
        display: flex;
        flex-direction: column;
        flex: 6;
        p {
          font-family: "Lora";
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #25282e;
          margin-bottom: 8px;
          width: 95%;
        }
        span {
          font-family: "Archivo";
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #888888;
          margin-right: 16px;
        }
      }
      .rightSide {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
        a {
          font-family: "Lora";
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #888888;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            color: #000;
          }
        }
      }

      &:hover {
        p {
          color: #888888;
        }
      }
    }
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 57px 52.5px;
  background: #f1f1f1;
  border: 1px solid #e8e8e8;
  position: relative;
  margin-top: 150px;
}

.footer .div1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer .div1 .socialMediaIcons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 44px 0;
}

.footer .div1 .socialMediaIcons svg {
  margin-right: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.footer .div1 .socialMediaIcons svg:hover {
  scale: 1.1;
}

.footer .div1 .logo {
  margin-top: 16px;
}

.footer .div1 p {
  font-size: 16px !important;
}

.footer .div1 a {
  margin-right: 11px;
  font-size: 15px;
  font-family: "Archivo";
  font-weight: 400;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
}

.footer .div2 a {
  font-size: 16px !important;
  line-height: 20px !important;
}

.footer .div4 input,
.footer .div4 button {
  width: 306px;
  height: 48px !important;
}

.footer .div4 input {
  margin-top: 24px;
  margin-bottom: 8px;
  padding: 12px 16px;
  border: 1px solid #888888;
  background-color: transparent;
}

.footer .div4 input:focus {
  outline: none;
}

.footer .div4.footerCol ul li {
  margin: 0;
}

.footer .footerCol {
  width: 25%;
}

.footer .footerCol h3 {
  font-family: "Lora";
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  color: #25282e;
  margin-bottom: 24px;
}

.footer .footerCol p {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #888888;
  width: 65%;
}

.footer .footerCol ul {
  list-style: none;
}

.footer .footerCol ul li {
  margin-bottom: 15px;
}

.footer .footerCol ul li a {
  font-family: "Archivo";
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer .footerCol ul li a:hover {
  color: #636363;
  padding-left: 5px;
}

// @media (max-width: 1650px) {
// .exhibitionsContainer .exhibitionCard {
//     height: 400px;
// }

// .exhibitionsContainer .exhibitionCard .image {
//     height: 240px;
//     width: 380px;
// }
// }

@media (max-width: 1570px) {
  // .selectedWorks .workCard:nth-child(n + 7) {
  //     display: none;
  // }
  .detailContainer img {
    background-color: #f2f2f2;
    margin-right: 80px;
  }
}
@media (max-width: 1430px) {
  .header .insideMenu {
    margin-left: 100px;
  }
  .exhibitionsContainer .exhibitions {
    column-count: 2;
    .exhibitionCard:nth-child(n + 5) {
      display: none;
    }
  }
  .publicationsContainer .publications .publicationCard:nth-child(n + 5) {
    display: none;
  }
  .biography div {
    width: 85%;
  }
  .publicationDetail ul li {
    max-width: 350px;
  }
}

@media (max-width: 1400px) {
  .selectedWorks .works {
    column-count: 3;

    .workCard:nth-child(n + 7) {
      display: none;
    }
  }
}

@media (max-width: 1296px) {
  .bannerContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 0px;
  }

  .header .insideMenu {
    margin-left: 50px;
  }
  .publicationDetail ul li {
    max-width: 300px;
  }
}

@media (max-width: 1204px) {
  .selectedWorks .works {
    column-count: 2;

    .workCard:nth-child(n + 5) {
      display: none;
    }
  }
  .detailContainer {
    flex-direction: column;
    img {
      margin-right: 0;
      margin-bottom: 40px;
    }

    div {
      width: 80%;
    }
  }
}

@media (max-width: 1200px) {
  .footerCol {
    width: 50% !important;
    margin-bottom: 30px;
  }
  .header .insideMenu {
    margin-left: 0;
  }
}
@media (max-width: 1168px) {
  .publicationsContainer .publications {
    justify-content: center;
  }
}

@media (max-width: 1100px) {
  .header .fixedMenu {
    position: fixed;
    z-index: 9;
    margin-right: 0;
    left: 0;
    right: 0;
    top: 76px;
    bottom: 100%;
    width: auto;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: white;
    overflow: scroll;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .header .insideMenu {
    margin: 0;
  }
  .header {
    .dropdown {
      display: none !important;
    }
    .socialMediaIcons {
      display: flex !important;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 !important;

      svg {
        margin: 0 10px !important;
      }
    }
  }
  .header ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0;

    &:last-child {
      margin-bottom: 40px;
    }
  }
  .header ul li a {
    font-size: 22px;
  }
  .header .iconBurger {
    display: block;
  }
  .header :checked ~ ul {
    bottom: 0;
  }
  .header svg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .fairsContainer .rightSide {
    padding: 10px 30px;
  }
  .fairsContainer .fairs .fairCard {
    flex-direction: column;
    height: 100%;
  }
  .fairsContainer .fairs .fairCard img {
    width: 100%;
  }
}

@media (max-width: 1066px) {
  // .exhibitionsContainer .exhibitions {
  //     justify-content: center;
  // }

  .publicationDetail {
    height: 600px;
  }
}
@media (max-width: 950px) {
  .biography div {
    flex-direction: column-reverse;
    .bioText {
      margin-top: 25px;
    }
    .pictureField img {
      width: 260px;
    }
  }

  .exhibitionsContainer .exhibitions {
    column-count: 1;
    .exhibitionCard:nth-child(n + 3) {
      display: none;
    }
  }
}
@media (max-width: 838px) {
  .selectedWorks .works {
    column-count: 1;
    .workCard:nth-child(n + 3) {
      display: none;
    }
  }
}
@media (max-width: 820px) {
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 10px 25px;
  }
  .img-slider .slide .info h2 {
    font-size: 35px;
  }
  .img-slider .slide .info p {
    width: 70%;
    font-size: 15px;
  }
  .img-slider .navigation {
    bottom: 25px;
  }
  .img-slider .navigation .btn {
    width: 10px;
    height: 10px;
    margin: 8px;
  }
}

@media (max-width: 750px) {
  .bannerContainer.publicationBanner img {
    width: 100%;
  }
  .footerCol {
    width: 100% !important;
    text-align: center;
    margin-bottom: 50px;
  }
  .footer .footerCol p {
    width: 100%;
  }
  .footer .div1 svg {
    margin: 0 auto;
  }
  // .newsContainer .news {
  //   padding: 0 0 50px 0;
  // }

  .newsContainer.shop .news {
    padding: 50px 0 50px 0;
  }

  .newsHeader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
  .newsCard {
    padding: 0 !important;
    margin: 0 18px;
  }
  .newsCard .title,
  .newsCard .description {
    padding: 0 16px;
  }

  .exhibitionsContainer {
    padding: 96px 0 74px 0;
    .exhibitions {
      margin-bottom: 0px;
      .exhibitionCard {
        width: 340px;
        margin-bottom: 0;
        .image {
          height: 220px;
          width: 340px;
        }
      }
    }
  }
  .publicationsContainer .publications .publicationCard:nth-child(n + 3) {
    display: none;
  }
  .exhibitionsContainer .publicationsContainer {
    margin-top: 150px;
    padding: 0;
  }
  .publicationsContainer.publicationsDetailContainer {
    margin-top: 80px;
    padding: 0;
  }

  .newsContainer .newsHeader .iconDiv {
    height: 35px !important;
    width: 35px !important;
  }

  .pressContainer {
    padding: 96px 1rem 74px 1rem;
    .rightSide {
      display: flex;
      flex-direction: column;
    }
  }
  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 40px;
    width: 100%;
  }

  .publicationDetail {
    height: 820px;
    padding: 37px 40px;
    ul {
      column-count: 2;
      li {
        max-width: 200px;
      }
    }
  }
}

@media (max-width: 648px) {
  .footer .div1 .socialMediaIcons {
    padding: 0;
  }
  .bannerContainer {
    padding: 0;
  }
  .bannerContainer img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .bannerContainer .rightSide {
    width: 100%;
  }
  .bannerContainer .rightSide .title {
    font-size: 32px;
    line-height: 30px;
  }
  .bannerContainer .rightSide .description {
    font-size: 16px;
    line-height: 20px;
  }

  .artistsContainer .artists {
    display: none;
  }

  .artistsContainer .namesDiv {
    column-count: 2;
    padding: 0 10px;
  }
  .subMenu {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    li {
      margin-left: 22px;
      a {
        font-size: 13px;
        line-height: 28px;
      }
      &:nth-last-of-type(1) {
        margin-right: 22px;
      }
    }
  }

  .bannerContainer .rightSide .title {
    width: 100%;
  }
  .bannerContainer .rightSide .description {
    width: 100%;
  }
  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 20px;
  }
  .detailContainer {
    padding: 0 40px;
    img {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
}

@media (max-width: 620px) {
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 10px 20px;
  }
  .img-slider .slide .info h2 {
    font-size: 30px;
  }
  .img-slider .slide .info p {
    width: 80%;
    font-size: 13px;
  }
  .img-slider .navigation {
    bottom: 15px;
  }
  .img-slider .navigation .btn {
    width: 8px;
    height: 8px;
    margin: 6px;
  }
  .bannerContainer.publicationBanner img {
    height: 500px;
  }
}
@media (max-width: 500px) {
  .newsCard {
    min-width: 94vw !important;
    margin: 0 24px 0 8px !important;
  }
  .newsContainer .newsCard.shopCard {
    min-width: 500px !important;
  }
}

@media (max-width: 420px) {
  .footer .div1 .socialMediaIcons {
    padding: 0 !important;
  }
  .footer .div4 input,
  .footer .div4 button {
    width: 220px;
  }
  .publicationDetail {
    height: 900px;
  }
  .artistsContainer .namesDiv li a img {
    width: 140px;
  }
  .selectedWorks {
    padding: 32px 0 0 0;
  }
}

@media (max-width: 360px) {
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 5px 10px;
  }
  .img-slider .slide .info h2 {
    font-size: 25px;
  }
  .img-slider .slide .info p {
    width: 90%;
    font-size: 11px;
  }
  .img-slider .navigation {
    bottom: 10px;
  }
}
/*# sourceMappingURL=style.css.map */
