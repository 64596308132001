body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Apercu Pro";
  src: url(/src/assets/fonts/apercu_regular_pro.otf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
.btnWhite {
  font-family: "Apercu Pro";
}

.btnWhite {
  width: 240px;
  height: 53px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #25282e;
  cursor: pointer;
}

.btnWhite:hover {
  background: #fff200;
}

.btnYellow {
  width: 240px;
  height: 53px;
}

.btnYellow,
.acceptButton {
  background: #fff200;
  font-family: "Apercu Pro";
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #25282e;
  cursor: pointer;
}

.acceptButton {
  width: max-content;
  height: max-content;
  padding: 0.5rem !important;
}

.acceptButton:hover {
  background: #fcf302 !important;
}

.smallBtn {
  max-width: fit-content !important;
  padding: 0 20px 0 20px;
}

.searchPopup {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
}

.searchPopup .searchField {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px 40px;
  background-color: #fff;
  margin-bottom: 16px;
}

.searchPopup .searchField input {
  flex: 8;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 120%;
  color: #25282e;
  padding: 32px 0;
}

.searchPopup .searchField input,
.searchPopup .searchField input:focus {
  border: none;
  outline: none;
}

.searchPopup .searchField input::placeholder {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 120%;
  color: #888888;
}

.searchPopup .searchField .searchPopupIcons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 16px;
}

.searchPopup .searchField .searchPopupIcons svg {
  cursor: pointer;
}

.searchPopup .searchResultField {
  width: 100%;
  min-height: 150px;
  padding: 32px 40px;
  display: none;
  flex-direction: column;
  background-color: #fff;
}

.searchPopup .searchResultField .resultInfo {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #888888;
  border: 1px solid #e8e8e8;
  width: 233px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  margin-bottom: 20px;
}

.searchPopup .searchResultField .resultInfo a {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #888888;
  margin-left: 4px;
  transition: all 0.3s ease;
}

.searchPopup .searchResultField .resultInfo a svg {
  margin-left: 4px;
}

.searchPopup .searchResultField .resultInfo a:hover {
  color: #000;
}

.searchPopup .searchResultField .resultInfo a:hover svg path {
  stroke: #000;
}

.searchPopup .searchResultField .result {
  display: flex;
  padding: 40px 70px 40px 70px;
  column-gap: 78px;
  cursor: pointer;
}

.searchPopup .searchResultField .result .resultTitle {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  width: 293px;
  min-width: 170px;
}

.searchPopup .searchResultField .result .resultItem {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  width: 829px;
}

.searchPopup .searchResultField .result:hover {
  background-color: #f0f0f0;
}

.searchPopup::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #e2e2e2;
  border-radius: 6px;
}

.searchPopup::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal .modalContent {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 1076px;
  height: 764px;
  padding: 22px 22px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal .modalContent svg {
  cursor: pointer;
}

.modal .modalContent .modalHeader {
  width: 100%;
  height: 50px;
}

.modal .modalContent .modalHeader .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal .modalContent .modalHeader .close:hover,
.modal .modalContent .modalHeader .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal .modalContent .modalBody {
  display: flex;
  align-items: center;
  column-gap: 50px;
  height: 612px;
}

.modal .modalContent .modalBody div {
  width: 224px;
  overflow-wrap: break-word;
}

.modal .modalContent .modalBody div h3 {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
}

.modal .modalContent .modalBody div p {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
}

.modal .modalContent .modalFooter {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
}

.shopModal {
  display: none;
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.shopModal .shopModalContent {
  background-color: #fff;
  margin: auto;
  width: 450px;
  height: 190px;
  padding: 17.5px 16px;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.shopModal .shopModalContent,
.shopModal .shopModalContent .shopModalHeader {
  display: flex;
  justify-content: space-between;
}

.shopModal .shopModalContent .shopModalHeader svg {
  cursor: pointer;
}

.shopModal .shopModalContent .shopModalHeader p {
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #888888;
}

.shopModal .shopModalContent .modalBody {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.shopModal .shopModalContent .modalBody a {
  width: 100%;
  text-decoration: none;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #25282e;
  cursor: pointer;
  padding: 8px 0;
}

.shopModal .shopModalContent .modalBody a:first-child {
  background-color: #fff200;
}

.shopModal.cartPage .shopModalContent {
  height: 280px;
}

.shopModal.cartPage .shopModalContent .modalBody {
  row-gap: 32px;
  align-items: center;
}

.shopModal.cartPage .shopModalContent .modalBody .icon {
  padding: 16px;
  width: 48px;
  height: 48px;
  background: #fff200;
}

.shopModal.cartPage .shopModalContent .modalBody .icon2 {
  padding: 14px;
  width: 48px;
  height: 48px;
  background: #25282e;
}

.shopModal.cartPage .shopModalContent .modalBody p {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #25282e;
  width: 254px;
  text-align: center;
}

.shopModal.cartPage .shopModalContent .modalBody .yellowBg {
  background-color: #fff200;
}

#myModal2 .shopModalContent {
  height: 324px;
}

#myModal2 .shopModalContent .btns {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
}

.header.shopHeader .none {
  display: none;
}

.header {
  height: 76px;
  padding: 16px 42px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 1000;
  top: 0;
  border-bottom: 1.5px solid #e6e6e6;
}

.header #searchBtnOfHeader {
  cursor: pointer;
}

.header #navToggle {
  position: absolute;
  top: -100px;
}

.header .insideMenu {
  margin-left: 200px;
}

.header ul {
  margin-right: 20px;
  list-style: none;
  display: flex;
  align-items: center;
}

.header ul li {
  display: inline-block;
  margin-left: 42px;
}

.header ul li a,
.header ul li .followDropdown {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: #888888;
  text-decoration: none;
  cursor: pointer;
}

.header ul li a:hover,
.header ul li .followDropdown:hover {
  color: #25282e;
}

.header ul .socialMediaIcons {
  display: none;
}

.header ul .socialMediaIcons svg {
  cursor: pointer;
}

.header ul .dropdownContent {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header ul .dropdownContent a {
  color: #575757;
  padding: 10px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.header ul .dropdownContent a svg:last-child {
  display: none;
}

.header ul .dropdownContent a:hover {
  background-color: rgb(233, 233, 233);
  color: #25282e;
}

.header ul .dropdownContent a:hover svg:last-child {
  display: flex;
}

.header ul .dropdownContent a:hover svg:first-child {
  display: none;
}

.header ul .dropdown:hover .dropdownContent {
  display: block;
}

.header .iconBurger {
  /* position: absolute;
  right: 5%;
  top: 50%; */
  /* transform: translateY(-50%); */
  display: none;
}

/* .header .iconBurger .line {
  width: 30px;
  height: 5px;
  background-color: #25282e;
  margin: 5px;
  border-radius: 3px;
}  */

.header .hasBadgeItem,
.header .hasBadgeItem .badge {
  display: flex;
  align-items: center;
}

.header .hasBadgeItem .badge {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #fff;
  background-color: #25282e;
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 9px;
  line-height: 8px;
  justify-content: center;
  margin-left: 8px;
}

.container {
  position: relative;
  top: 76px;
}

.img-slider {
  position: relative;
  height: 772px;
  width: 100%;
  background: #25282e;
}

.img-slider #logo2 {
  position: absolute;
  top: 64px;
  left: 64px;
  z-index: 2;
}

.img-slider .slide {
  z-index: 1;
  position: absolute;
  width: 100%;
  clip-path: circle(0% at 0 50%);
}

.img-slider .slide.active {
  clip-path: circle(150% at 0 50%);
  transition: 0.7s;
  transition-property: clip-path;
}

.img-slider .slide img {
  z-index: 1;
  height: 772px;
  width: 100%;
  object-fit: cover;
}

.img-slider .navigation {
  bottom: 30px;
  z-index: 3;
}

.img-slider .navigation,
.img-slider .titleField {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.img-slider .titleField {
  flex-direction: column;
  padding: 0px;
  margin: auto;
  height: 100%;
  text-align: center;
  color: #fff;
  z-index: 2;
  top: 0;
}

.img-slider .titleField p {
  font-weight: 400;
  font-size: 21px;
  margin-top: 16px;
  margin-bottom: 36px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.img-slider .titleField h2:first-child {
  font-weight: 700;
  font-size: 61px;
}

.img-slider .titleField h2:nth-child(2) {
  font-weight: 500;
  font-size: 61px;
  margin-top: 16px;
}

.img-slider .navigation .circle {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  margin: 13px;
  cursor: pointer;
}

.img-slider .navigation .circle.active {
  background-color: #fff200 !important;
}

.exhibitionsContainer {
  text-align: center;
  padding: 96px 2.8rem 74px 2.8rem;
}

.exhibitionsContainer h2 {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
  text-align: start;
  margin-bottom: 28px;
}

.exhibitionsContainer .buttons {
  display: flex;
  gap: 16px;
}

.exhibitionsContainer.allExhibitions {
  padding: 64px 2.8rem 0px 2.8rem;
}

.exhibitionsContainer h1 {
  font-size: 47px;
  line-height: 120%;
  text-align: center;
  overflow-wrap: break-word;
}

.exhibitionsContainer h1,
.exhibitionsContainer h1.detailExhibition {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 700;
  color: #25282e;
}

.exhibitionsContainer h1.detailExhibition {
  margin-bottom: 1rem;
  font-size: 27px;
  line-height: 140%;
}

.exhibitionsContainer .exhibitions {
  margin-top: 40px;
  margin-bottom: 72px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 470px);
  justify-content: space-between;
}

.exhibitionsContainer .exhibitions.exhibitionList {
  margin-top: 64px;
}

.exhibitionsContainer .exhibitions.exhibitionList .exhibitionCard {
  margin: 0 0 40px 0;
}

.exhibitionsContainer .exhibitionCard {
  height: 410px;
  width: 420px;
  text-align: start;
  margin: 0 auto 40px auto;
  cursor: pointer;
}

.exhibitionsContainer .exhibitionCard .image {
  height: 280px;
  width: 416px;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.exhibitionsContainer .exhibitionCard .image img {
  height: 280px;
  width: 100%;
  object-fit: none;
  transition: all 0.2s ease-in;
}

.exhibitionsContainer .exhibitionCard p {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
  margin-top: 16px;
  margin-bottom: 8px;
  overflow-wrap: break-word;
}

.exhibitionsContainer.artistDetail .exhibitionCard .artistName {
  margin-top: 16px;
  margin-bottom: 4px;
}

.exhibitionsContainer.artistDetail .exhibitionCard .artistName,
.exhibitionsContainer.artistDetail .exhibitionCard .workName {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  color: #25282e;
  overflow-wrap: break-word;
}

.exhibitionsContainer.artistDetail .exhibitionCard .workName {
  margin-top: 0;
  margin-bottom: 8px;
}

.exhibitionsContainer.artistDetail .exhibitionCard .company {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #25282e;
  overflow-wrap: break-word;
  margin: 16px 0 4px 0;
}

.exhibitionsContainer .exhibitionCard .date {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #888888;
  overflow-wrap: break-word;
}

.exhibitionsContainer.artistDetail .exhibitionCard .date {
  font-size: 16px;
  line-height: 20px;
}

.exhibitionsContainer .exhibitionCard p.date {
  margin: 4px 0;
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #888888;
}

.exhibitionsContainer .exhibitionCard .readMore {
  display: none;
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 17.0007px;
  line-height: 26px;
  text-decoration: underline;
  color: #25282e;
  cursor: pointer;
}

.exhibitionsContainer .exhibitionCard:hover .image {
  opacity: 0.4;
  height: 220px;
}

.exhibitionsContainer .exhibitionCard:hover .image img {
  height: 220px;
}

.exhibitionsContainer .exhibitionCard:hover .readMore {
  display: block;
}

.newsContainer {
  width: 100%;
  height: 100%;
  background-color: #25282e;
}

.newsContainer.shop {
  width: 100%;
  height: auto;
}

.artistsNews {
  align-items: center;
}

.newsContainer .newsHeader {
  display: flex;
  align-items: center;
  padding: 30px 50px;
  justify-content: center !important;
}

.newsContainer .newsHeader h2 {
  flex: 3;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #ffffff;
}

.newsContainer.artistDetail .newsHeader h2 {
  margin-left: 16px;
}

.newsContainer .newsHeader .newsRightSide {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsContainer .newsHeader .newsRightSide button {
  margin-right: 12px;
}

.newsContainer .newsHeader .newsRightSide .btnWhite {
  width: 262px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.newsContainer .newsHeader .newsRightSide .iconDiv {
  height: 53px;
  width: 53px;
  padding: 8.83333px;
  background: #333944;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  margin: 0 4px;
  cursor: pointer;
}

.whiteIconDiv {
  color: #333944 !important;
  background: #fff !important;
}
/* 
.newsContainer .news {
  display: flex;
  padding: 0 26px 26px 26px;
  background-color: #25282e;
  overflow-x: auto;
  scroll-behavior: smooth;
} */

.newsContainer .news::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.newsContainer .newsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0 24px;
  cursor: pointer;
  font-family: "Apercu Pro";
  min-width: 448px;
  max-width: 448px;
  height: 100%;
  gap: 24px;
}

.newsContainer .newsCard .image {
  background-color: #fff;
}

.newsContainer .newsCard.shopCard {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  padding: 16px;
  margin: 0 24px;
  cursor: pointer;
  font-family: "Apercu Pro";
  height: 100%;
  gap: 24px;
  margin-right: 64px;
}

.newsContainer .newsCard img {
  max-height: 560px;
  width: 100%;
  object-fit: fill;
}

.newsContainer .newsCard.shopCard img {
  max-height: 265px;
  width: 196px;
  object-fit: fill;
}

.newsContainer .newsCard.shopCard .rightSide {
  width: 100% !important;
}

.newsContainer .newsCard img.evenNumber {
  order: 3;
}

.newsContainer .newsCard .title {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #ffffff;
  overflow-wrap: break-word;
}

.newsContainer .newsCard.shopCard .title {
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  width: 100%;
  color: #ffffff;
  overflow-wrap: break-word;
}

.newsContainer .newsCard .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  overflow-wrap: break-word;
  margin-top: 16px;
}

.newsContainer.shop .newsCard .description {
  width: 100% !important;
}

.newsContainer .newsCard.yellowBg:hover {
  background-color: #fff200;
}

.newsContainer .newsCard.yellowBg:hover .title,
.newsContainer .newsCard.yellowBg:hover .description {
  color: #25282e;
}

.newsContainer .newsCard.opacityBg:hover img {
  opacity: 0.7;
}

.detailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 70px;
  margin-top: 68px;
  margin-bottom: 88px;
}

.detailContainer img {
  background-color: #f2f2f2;
  margin-right: 134px;
}

.detailContainer h2 {
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  margin-bottom: 24px;
}

.detailContainer h2,
.detailContainer p {
  font-family: "Apercu Pro";
  color: #25282e;
}

.detailContainer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.publicationDetail {
  height: 560px;
  width: 100%;
  background: #25282e;
  padding: 37px 70px;
}

.publicationDetail div {
  display: flex;
  justify-content: space-between;
}

.publicationDetail div h2 {
  width: 80px;
  height: 36px;
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.publicationDetail div button {
  width: 146px;
}

.publicationDetail ul {
  margin-top: 58px;
  list-style: none;
  column-count: 3;
}

.publicationDetail ul li {
  max-width: 400px;
  margin-bottom: 46px;
  margin-right: 33px;
}

.publicationDetail ul .title {
  font-weight: 500;
  color: #ffffff;
}

.publicationDetail ul .title,
.publicationDetail ul .description {
  font-family: "Apercu Pro";
  font-size: 16px;
  line-height: 120%;
}

.publicationDetail ul .description {
  font-weight: 700;
  color: #fff200;
  margin-top: 16px;
}

.bannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 182px 0;
  padding: 0 40px;
}

.bannerContainer img {
  height: 450px;
  width: 648px;
  object-fit: fill;
}

.bannerContainer.publicationBanner img {
  height: 600px;
  width: 648px;
  object-fit: cover;
  background: #f1f1f1;
  overflow: hidden;
}

.bannerContainer.publicationBanner {
  margin: 0 !important;
}

.bannerContainer .rightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  width: 648px;
  height: 450px;
  padding: 0 80px;
}

.bannerContainer.publicationBanner .rightSide {
  background: #fff;
  align-items: flex-start;
  padding: 0 20px 0 120px;
}

.bannerContainer .rightSide .title {
  font-size: 40px;
  line-height: 50px;
  color: #25282e;
}

.bannerContainer .rightSide .title,
.bannerContainer .rightSide .description {
  font-family: "Apercu Pro";
  font-weight: 400;
  text-align: center;
  width: 70%;
}

.bannerContainer .rightSide .description {
  font-size: 18px;
  line-height: 28px;
  color: #888888;
  margin: 24px 0 64px 0;
  overflow-wrap: break-word;
}

.bannerContainer.publicationBanner .rightSide .description {
  text-align: start;
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #888888;
}

.bannerContainer.publicationBanner .rightSide .title {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
  text-align: start;
}

.fairsContainer {
  text-align: center;
}

.fairsContainer h2 {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
}

.fairsContainer .fairs {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.fairsContainer .fairs::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.fairsContainer .fairs .fairCard a {
  display: flex;
  margin: 12px;
  height: 240px;
  width: 635px;
  transition: all 0.2s ease-in;
}

.fairsContainer .fairs .fairCard:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.fairsContainer .fairs .fairCard a img {
  transition: all 0.2s ease-in;
}

.fairsContainer .fairs .fairCard:hover a img {
  opacity: 0.4;
  transition: all 0.2s ease-in;
  height: 220px;
}

.fairsContainer .fairs .fairCard:hover a .details {
  text-decoration: underline;
  transition: all 0.2s ease-in;
  display: block;
}

.fairsContainer img {
  height: 100%;
  width: 305px;
  object-fit: none;
}

.fairsContainer .rightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 330px;
  height: 100%;
  padding: 0 30px;
  gap: 24px;
  text-align: start;
}

.fairsContainer .rightSide .title {
  font-size: 22px;
  line-height: 26px;
  color: #25282e;
}

.fairsContainer .rightSide .title,
.fairsContainer .rightSide .date {
  font-family: "Apercu Pro";
  font-weight: 400;
  overflow-wrap: break-word;
}

.fairsContainer .rightSide .date {
  font-size: 16px;
  line-height: 22px;
  color: #888888;
}

.fairsContainer .rightSide .details {
  display: none;
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-decoration: underline;
  color: #25282e;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.artistsContainer h1 {
  margin: 62px 0;
  text-align: center;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #25282e;
}

.artistsContainer .artists {
  display: flex;
  margin: 0 auto;
  width: 95%;
}

.artistsContainer .artists .photosCol {
  width: 30%;
  margin: 0 12px;
}

.artistsContainer .artists .photosCol .artistName {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #25282e;
  background-color: #fff;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 10px;
}

.artistsContainer .artists .photosCol img {
  width: 100%;
  transition: all 0.5s ease;
}

.artistsContainer .artists .photosCol .artistCard {
  position: relative;
  cursor: pointer;
}

.artistsContainer .artists .photosCol .artistCard:hover img {
  opacity: 0.4;
}

.artistsContainer .artists .photosCol .artistCard:hover .artistName {
  display: block;
}

.artistsContainer .namesDiv {
  margin: 90px 0 90px 0;
}

.fairsDetailContainer .namesDiv {
  margin: 60px 0 60px 0;
}

.artistsContainer .namesDiv,
.fairsDetailContainer .namesDiv {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
}

.artistsContainer .namesDiv .namesColFor2Columns,
.artistsContainer .namesDiv .namesColFor3Columns,
.fairsDetailContainer .namesDiv .namesColFor2Columns,
.fairsDetailContainer .namesDiv .namesColFor3Columns {
  margin: 0 auto;
}

.artistsContainer .namesDiv .namesColFor2Columns li,
.artistsContainer .namesDiv .namesColFor3Columns li,
.fairsDetailContainer .namesDiv .namesColFor2Columns li,
.fairsDetailContainer .namesDiv .namesColFor3Columns li {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
  text-align: center;
}

.artistsContainer .namesDiv .namesColFor2Columns li a,
.artistsContainer .namesDiv .namesColFor3Columns li a,
.fairsDetailContainer .namesDiv .namesColFor2Columns li a,
.fairsDetailContainer .namesDiv .namesColFor3Columns li a {
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #25282e;
  text-decoration: none;
}

.artistsContainer .namesDiv .namesColFor2Columns li a:hover,
.artistsContainer .namesDiv .namesColFor3Columns li a:hover,
.fairsDetailContainer .namesDiv .namesColFor2Columns li a:hover,
.fairsDetailContainer .namesDiv .namesColFor3Columns li a:hover {
  font-weight: 700;
}

.artistsContainer .namesDiv .namesColFor2Columns li a:hover ~ img,
.artistsContainer .namesDiv .namesColFor3Columns li a:hover ~ img,
.fairsDetailContainer .namesDiv .namesColFor2Columns li a:hover ~ img,
.fairsDetailContainer .namesDiv .namesColFor3Columns li a:hover ~ img {
  margin-top: 20px;
  justify-content: center;
  z-index: 2;
  width: 220px;
  opacity: 1;
  transition: all 0.6s ease;
}

.artistsContainer .namesDiv .namesColFor2Columns li img,
.artistsContainer .namesDiv .namesColFor3Columns li img,
.fairsDetailContainer .namesDiv .namesColFor2Columns li img,
.fairsDetailContainer .namesDiv .namesColFor3Columns li img {
  width: 0;
  margin-top: 0;
  opacity: 0;
}

.artistsContainer .namesDiv .namesColFor2Columns,
.fairsDetailContainer .namesDiv .namesColFor2Columns {
  display: none;
}

.biography {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  /* margin-top: 80px; */
  width: 100%;
}

.biography > div {
  width: 73%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.biography > div .bioText {
  flex: 3;
}

.biography > div .bioText h1 {
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
  word-spacing: 100vw;
}

.biography > div .bioText p {
  line-height: 150%;
  color: #888888;
  margin: 30px 0;
}

.biography > div .bioText .downloadCV {
  line-height: 28px;
  text-decoration-line: underline;
  color: #888888;
}

.biography > div .pictureField {
  flex: 2;
}

.biography > div .pictureField > div {
  display: inline;
  float: right;
}

.biography > div .pictureField img {
  margin-bottom: 10px;
}

.biography > div .pictureField a {
  line-height: 16px;
  text-decoration-line: underline;
  color: #888888;
  display: block;
  margin-top: 6px;
}

.selectedWorks {
  background: #f1f1f1;
  max-height: 910px;
  text-align: center;
  padding: 32px 2.8rem 32px 2.8rem;
  margin: 64px 0 0 0;
}

.selectedWorks h1 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
  margin-bottom: 10px;
}

.selectedWorks .works {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: space-between;
}

.selectedWorks .works .workCard {
  width: 306px;
  height: 362.5px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  margin: 0 auto;
}

.selectedWorks .works .workCard div {
}

.selectedWorks .works .workCard div img {
  width: 306px;
  height: 306px;
  transition: all 0.5s ease;
}

.selectedWorks .works .workCard p {
  position: absolute;
  width: 50%;
  left: 80px;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #fff;
  display: none;
}

.selectedWorks .works .workCard:hover img {
  opacity: 0.5;
}

.selectedWorks .works .workCard:hover p {
  display: block;
}

.selectedWorks button {
  margin-top: 20px;
}

.newsPageContainer {
  padding: 0 2.8rem 0 2.8rem;
  font-family: "Apercu Pro", sans-serif;
  text-align: center;
}

.newsPageContainer .news {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 73px auto;
}

.newsPageContainer .news .newsCol a {
  text-decoration: none;
}

.newsPageContainer .news .newsCol .news-card {
  text-align: start;
  width: 416px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto 24px auto;
  cursor: pointer;
}

.newsPageContainer .news .newsCol .news-card .newsImg {
  background-color: #000;
  display: flex;
  overflow: hidden;
}

.newsPageContainer .news .newsCol .news-card .newsImg img {
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
}

.newsPageContainer .news .newsCol .news-card h4 {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
}

.newsPageContainer .news .newsCol .news-card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #25282e;
}

.newsPageContainer .news .newsCol .news-card:hover > .newsImg img {
  opacity: 0.6;
  object-fit: contain;
  scale: 1.05;
}

.publicationsContainer {
  padding: 3rem 1rem;
  text-align: center;
  margin-top: 50px;
}

.publicationsContainer h2 {
  text-align: start;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  margin-left: 23px;
  margin-bottom: 32px;
}

.publicationsContainer h1 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  color: #25282e;
  text-align: center;
  margin: 100px 0 40px 0;
}

.publicationsContainer .publications {
  margin: 20px 0;
  text-align: start;
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  column-gap: 24px;
  justify-content: space-between;
}

.publicationsContainer .publications .publicationCard {
  width: 306px;
  height: 503px;
  cursor: pointer;
}

.publicationsContainer .publications .publicationCard .imgDiv {
  background-color: #f2f2f2;
  transition: all 0.4s ease-in;
}

.publicationsContainer .publications .publicationCard .imgDiv img {
  height: 324px;
}

.publicationsContainer .publications .publicationCard h3 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  margin-top: 19.13px;
  margin-bottom: 11.77px;
}

.publicationsContainer .publications .publicationCard p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #888888;
}

.publicationsContainer .publications .publicationCard:hover .imgDiv {
  background-color: rgba(0, 0, 0, 0.3);
}

.publicationsContainer .publications .publicationCard:hover h3 {
  color: #888888;
}

.pressContainer {
  padding: 96px 4rem 74px 4rem;
  margin-top: 50px;
}

.pressContainer h2 {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  margin-bottom: 36px;
}

.pressContainer h2,
.pressContainer .divButton {
  text-align: center;
}

.pressContainer ul {
  margin-bottom: 28px;
}

.pressContainer ul li {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 0;
}

.pressContainer ul li .leftSide {
  display: flex;
  flex-direction: column;
  flex: 6;
}

.pressContainer ul li .leftSide p {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #25282e;
  margin-bottom: 8px;
  width: 95%;
}

.pressContainer ul li .leftSide span {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #888888;
  margin-right: 16px;
}

.pressContainer ul li .rightSide {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.pressContainer ul li .rightSide a {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  text-decoration: none;
  transition: all 0.3s ease;
}

.pressContainer ul li .rightSide a:hover {
  color: #000;
}

.pressContainer ul li:hover p {
  color: #888888;
}

.exhibitionOverview {
  display: flex;
  justify-content: space-evenly;
  padding: 72px 2.8rem 48px 2.8rem;
}

.exhibitionOverview .leftSide {
  display: flex;
  flex-direction: column;
  font-family: "Apercu Pro", sans-serif;
}

.exhibitionOverview .leftSide h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #25282e;
  margin-bottom: 8px;
  margin-top: 93px;
}

.exhibitionOverview .leftSide h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  color: #25282e;
  margin-bottom: 72px;
}

.exhibitionOverview .leftSide p:first-child {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #25282e;
  margin-bottom: 4px;
}

.exhibitionOverview .leftSide .date {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #888888;
  margin-bottom: 60px;
}

.exhibitionOverview .leftSide a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #888888;
}

.exhibitionOverview .rightSide {
  max-width: 747px;
}

.exhibitionOverview .rightSide img {
  width: 747px;
  height: 503px;
  margin-bottom: 40px;
}

.exhibitionOverview .rightSide p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
  margin-bottom: 16px;
}

.exhibitionOverview .rightSide a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #888888;
}

.gallery {
  width: 100%;
  height: 683px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 72px;
  margin-top: 48px;
}

.gallery h2 {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  margin: 8px 0 44px 0;
  text-align: center;
}

.gallery .content {
  position: relative;
  display: flex;
  width: 1128px;
  height: 595px;
  overflow: hidden;
}

.gallery .content .slide {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all 0.6s linear;
}

.gallery .content .slide img {
  width: 0;
  height: 0;
}

.gallery .content .slide.active {
  height: 100%;
  width: 100%;
  opacity: 1;
}

.gallery .content .slide.active img {
  width: 1128px;
  height: 595px;
}

.gallery .content .backDisabled,
.gallery .content .backActived {
  position: absolute;
  right: 80px;
  bottom: 24px;
  cursor: pointer;
}

.gallery .content .nextDisabled,
.gallery .content .nextActived {
  cursor: pointer;
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.gallery .content .backActived,
.gallery .content .nextDisabled {
  display: none !important;
}

.fairsPageContainer {
  /* margin-top: 76px; */
  padding: 103px 2.8rem 87px 2.8rem;
}

.fairsPageContainer .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.fairsPageContainer .head div:first-child {
  flex: 1;
}

.fairsPageContainer .head div:last-child {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.fairsPageContainer .head div:last-child a,
.fairsPageContainer .head div:last-child span {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  margin-left: 16px;
  text-decoration: none;
}

.fairsPageContainer .head div:last-child a:hover,
.fairsPageContainer .head div:last-child span:hover {
  font-weight: 700;
}

.fairsPageContainer .head div:last-child span {
  font-weight: 700 !important;
}

.fairsPageContainer .head h2 {
  font-family: "Apercu Pro", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #25282e;
  flex: 1;
  text-align: center;
}

.fairsPageContainer .fairs {
  display: grid;
  grid-template-columns: repeat(auto-fill, 420px);
  justify-content: space-between;
  gap: 24px;
}

.fairsPageContainer .fairs .fairCard {
  background-color: #000;
  width: 416px;
  height: 326px;
  cursor: pointer;
  position: relative;
  font-family: "Apercu Pro", sans-serif;
  margin: 0 auto;
}

.fairsPageContainer .fairs a .fairCard img {
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.fairsPageContainer .fairs a .fairCard div {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 30px;
}

.fairsPageContainer .fairs a .fairCard div p {
  transition: all 0.5s cubic-bezier(0.17, 0.67, 0.55, 0.98);
}

.fairsPageContainer .fairs a .fairCard div .title {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #ffffff;
  width: 65%;
  margin: 0 auto;
}

.fairsPageContainer .fairs a .fairCard div .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  width: 100%;
  display: none;
  margin-top: 12px;
}

.fairsPageContainer .fairs a .fairCard div .date {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
}

.fairsPageContainer .fairs a .fairCard:hover img {
  opacity: 0.25;
}

.fairsPageContainer .fairs a .fairCard:hover div {
  justify-content: center;
}

.fairsPageContainer .fairs a .fairCard:hover div .title {
  font-size: 27px;
  line-height: 140%;
  width: 82%;
}

.fairsPageContainer .fairs a .fairCard:hover div .description {
  display: block;
}

.fairsPageContainer .fairs a .fairCard:hover div .date {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
}

.fairsDetailContainer {
  /* margin-top: 76px; */
  font-family: "Apercu Pro", sans-serif;
}

.fairsDetailContainer .artistsHeading {
  text-align: center;
  margin-top: 16px;
}

.fairsDetailContainer .introduceImage {
  width: 100%;
  height: 346px;
}

.fairsDetailContainer .introduce {
  display: flex;
  justify-content: space-between;
  padding: 48px 2.8rem 0 2.8rem;
}

.fairsDetailContainer .introduce h4 {
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #25282e;
  width: 305px;
}

.fairsDetailContainer .introduce p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
  width: 50%;
}

.fairsDetailContainer .newsContainer {
  margin-top: 104px;
  margin-bottom: 235px;
  height: 883px;
}

.fairsDetailContainer .newsContainer .news .newsCard {
  font-family: "Apercu Pro", sans-serif;
  align-items: flex-start;
}

.fairsDetailContainer .newsContainer .news .newsCard .title {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #f1f1f1;
}

.fairsDetailContainer .newsContainer .news .newsCard .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f1f1f1;
}

.newsDetailPage {
  margin-top: 6rem;
  font-family: "Apercu Pro", sans-serif;
}

.newsDetailPage .newsDetail {
  padding: 0px 2.8rem 0 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsDetailPage .newsDetail .leftSide {
  width: 796px;
}

.newsDetailPage .newsDetail .leftSide img {
  width: 636px;
  height: 530px;
  margin: 0 auto;
}

.newsDetailPage .newsDetail .rightSide {
  max-width: 950px;
  margin-left: 30px;
}

.newsDetailPage .newsDetail .rightSide h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #25282e;
  margin-bottom: 36px;
}

.newsDetailPage .newsDetail .rightSide p {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #888888;
}

.newsDetailPage .newsContainer {
  background-color: #fff;
}

.newsDetailPage .newsContainer .icon {
  margin-left: 8px;
  cursor: pointer;
}

.newsDetailPage .newsContainer .newsHeader {
  padding: 30px 2.8rem;
}

.newsDetailPage .newsContainer .newsHeader h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  margin: 0;
}

.newsDetailPage .newsContainer .newsHeader .newsRightSide {
  justify-content: flex-end;
}

.newsDetailPage .newsContainer .news {
  background-color: #fff;
}

.newsDetailPage .newsContainer .news .newsCard {
  align-items: flex-start;
  margin: 0;
}

.newsDetailPage .newsContainer .news .newsCard .title {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
}

.newsDetailPage .newsContainer .news .newsCard .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #25282e;
}

.searchPage {
  font-family: "Apercu Pro", sans-serif;
}

.searchPage .fairsPageContainer {
  padding: 0px 2.8rem 87px 2.8rem !important;
}

.searchPage .publicationsContainer {
  padding: 0px 2.8rem 20px 2.8rem;
}

.searchPage .newsPageContainer {
  padding: 0 2.8rem 150px 2.8rem;
}

.searchPage .exhibitionsContainer {
  padding: 96px 2.8rem 0px 2.8rem;
}

.searchPage .artistResults {
  padding: 0 2.8rem 0 2.8rem;
}

.searchPage .artistResults .artistsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  justify-content: space-between;
  row-gap: 50px;
}

.searchPage .artistResults .artistsList .artistsCard img {
  width: 306px;
  height: 306px;
  cursor: pointer;
}

.searchPage .artistResults .artistsList .artistsCard p {
  margin-top: 15.33px;
  font-weight: 700;
  font-size: 15.3966px;
  line-height: 120%;
  color: #25282e;
  cursor: pointer;
}

.searchPage .pageTitle {
  margin-top: 56px;
  margin-bottom: 53px;
  padding: 0 2.8rem 0 2.8rem;
}

.searchPage .pageTitle,
.searchPage .titles {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
}

.searchPage .titles {
  display: flex;
  gap: 8px;
  margin-bottom: 44px !important;
}

.searchPage .artistsCard,
.searchPage .exhibitionCard,
.searchPage .fairCard,
.searchPage .publicationCard,
.searchPage .news-card {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.aboutPage {
  font-family: "Apercu Pro";
}

.aboutPage .gallery h2 {
  align-self: flex-start;
  padding-left: 2.8rem;
}

.aboutPage .about {
  margin: 48px 0 72px 0;
  padding: 0 15rem;
}

.aboutPage .about h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #25282e;
  margin-bottom: 24px;
}

.aboutPage .about p {
  font-weight: 400;
  font-size: 16px;
  line-height: 141%;
  color: #25282e;
}

.aboutPage .teamDiv {
  width: 100%;
  min-height: 956px;
  background: #f1f1f1;
  padding: 56px 2.8rem 48px 2.8rem;
}

.aboutPage .teamDiv h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  text-align: center;
  margin-bottom: 44px;
}

.aboutPage .teamDiv .team {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: space-between;
  row-gap: 24px;
}

.aboutPage .teamDiv .team .personCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 306px;
  height: 374px;
  margin: 0 auto;
}

.aboutPage .teamDiv .team .personCard img {
  width: 306px;
  height: 306px;
  filter: gray;
  -webkit-filter: grayscale(1);
  transition: all 0.3s ease-in-out;
}

.aboutPage .teamDiv .team .personCard h5 {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  margin-top: 16px;
  margin-bottom: 8px;
}

.aboutPage .teamDiv .team .personCard p {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #333944;
}

.aboutPage .teamDiv .team .personCard:hover img {
  filter: none;
  -webkit-filter: grayscale(0);
}

.aboutPage .locationDiv {
  padding: 0 2.8rem;
}

.aboutPage .locationDiv h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25282e;
  text-align: start;
  margin-bottom: 44px;
}

.aboutPage .locationDiv .locations {
  display: grid;
  grid-template-columns: repeat(auto-fill, 430px);
  justify-content: space-between;
  row-gap: 50px;
}

.aboutPage .locationDiv .locations .location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.aboutPage .locationDiv .locations .location img {
  width: 416px;
  height: 306px;
}

.aboutPage .locationDiv .locations .location h5 {
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  margin-top: 16px;
  margin-bottom: 8px;
}

.aboutPage .locationDiv .locations .location p {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #333944;
}

.aboutPage .contactDiv {
  margin-top: 72px;
  margin-bottom: -74px;
  display: flex;
  align-items: center;
  position: relative;
}

.aboutPage .contactDiv img {
  width: 687px;
  height: 687px;
}

.aboutPage .contactDiv .contactUs {
  background-color: #fff200;
  width: 67.8vw;
  padding: 14px 0 14px 110px;
  /* position: absolute; */
  right: 0;
}

.aboutPage .contactDiv .contactUs h3 {
  font-weight: 700;
  font-size: 61px;
  line-height: 120%;
  color: #25282e;
  margin-bottom: 48px;
}

.aboutPage .contactDiv .contactUs p {
  width: 316px;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #888888;
  margin-bottom: 16px;
}

.shopDetailsPage {
  padding: 0 !important;
}

.shopPage {
  padding: 0 2.8rem 0 2.8rem;
}

.shopPage .shopSlider {
  display: flex;
  flex-direction: column;
}

.shopPage .shopSlider .product {
  display: flex;
}

.shopPage .shopSlider .product .leftSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shopPage .shopSlider .product .leftSide .names {
  display: flex;
  overflow: hidden;
}

.shopPage .shopSlider .product .leftSide .names .productName {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
  width: 0;
  height: 0;
  opacity: 0;
}

.shopPage .shopSlider .product .leftSide .names .productName.active {
  width: 494px;
  min-height: 197px;
  opacity: 1;
}

.shopPage .shopSlider .product .leftSide .navigation {
  display: flex;
  align-items: center;
}

.shopPage .shopSlider .product .leftSide .navigation .circle {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  margin-right: 13px;
  cursor: pointer;
  transition: all 0.5s linear;
}

.shopPage .shopSlider .product .leftSide .navigation .circle.active {
  background-color: #25282e !important;
  border: none;
}

.shopPage .shopSlider .product .rightSide {
  display: flex;
  overflow: hidden;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px !important;
  width: 50%;
}

.shopPage .shopSlider .product .rightSide .slideImg {
  transition: all 0.5s linear;
  height: 100%;
}

.shopPage .shopSlider .product .rightSide .slideImg:not(.active) {
  width: 0;
  height: 0;
  opacity: 0;
}

.shopPage .shopPubsField {
  text-align: center;
  margin-top: 90px;
}

.shopPage .shopPubsField .viewMoreBtn {
  margin: 93px 0;
}

.shopPage .shopPubsField .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
}

.shopPage .shopPubsField .top h3 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #25282e;
}

.shopPage .shopPubsField .top button {
  width: 177px !important;
  align-items: center;
}

.shopPage .shopPubsField .shopPubs {
  text-align: start;
  display: grid;
  grid-template-columns: repeat(auto-fill, 416px);
  column-gap: 24px;
  row-gap: 40px;
  justify-content: space-between;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .imgDiv {
  display: flex;
  position: relative;
  background-color: #000;
  transition: all 0.4s linear;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .imgDiv img {
  width: 100%;
  background: #f2f2f2;
  transition: all 0.4s linear;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .imgDiv button {
  transition: height 0.4s linear;
  cursor: pointer;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: #fff200;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  border: none;
  position: absolute;
  bottom: 0;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard:hover .imgDiv img {
  opacity: 0.6;
  padding: 0.5px;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard:hover .imgDiv button {
  width: 100%;
  height: 50px;
  opacity: 1;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .info {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .info h4 {
  width: 290px;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 150%;
  color: #25282e;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .info p {
  width: 86px;
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 21px;
  line-height: 120%;
  text-align: right;
  color: #888888;
}

.shopPage .shopPubsField select {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  width: 320px;
  height: 53px;
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #25282e;
}

.shopPage .shopPubsField select option {
  font-size: 16px;
  padding: 10px 0;
}

.shopPage .shopPubsField select:focus {
  outline: 1px solid #aaaaaa;
}

.shopPage .shopBanner {
  display: flex;
  align-items: center;
  margin-top: 96px;
}

.shopPage .shopBanner .leftSide {
  width: 50%;
  display: flex;
  align-items: center;
  height: 636px;
}

.shopPage .shopBanner .leftSide img {
  height: 100%;
}

.shopPage .shopBanner .rightSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 134px;
}

.shopPage .shopBanner .rightSide h2 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 47px;
  line-height: 120%;
  color: #25282e;
}

.shopPage .shopBanner .rightSide p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
  margin: 16px 0 36px 0;
}

.shopPage .shopDetailBanner {
  padding: 0 2.8rem 0 2.8rem;
  display: flex;
  align-items: center;
  margin-top: 78px;
}

.shopPage .shopDetailBanner .leftSide {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 636px;
  background: #f2f2f2;
}

.shopPage .shopDetailBanner .leftSide img {
  height: 100%;
}

.shopPage .shopDetailBanner .rightSide {
  width: 50%;
  min-height: 636px;
  padding-left: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.shopPage .shopDetailBanner .rightSide .category {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
}

.shopPage .shopDetailBanner .rightSide .title {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  color: #25282e;
  margin: 48px 0;
  width: 70%;
}

.shopPage .shopDetailBanner .rightSide .description {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
  margin-bottom: 48px;
  width: 80%;
}

.shopPage .shopDetailBanner .rightSide .amount {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 27px;
  line-height: 120%;
  color: #25282e;
  margin-top: 26px;
  margin-bottom: 24px;
}

.shopPage .shopDetailBanner .rightSide .btnYellow {
  width: 192px !important;
}

.shopPage .shopDetailBanner .rightSide .counter {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.shopPage .shopDetailBanner .rightSide .counter .piece {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #25282e;
}

.shopPage .shopDetailBanner .rightSide .counter button {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  color: #25282e;
  padding: 2px 10px;
  font-size: 20px;
  cursor: pointer;
}

.shopPage .bookInfo {
  padding: 0 2.8rem 0 2.8rem;
}

.shopPage .bookInfo .bookInfoInside {
  border-top: 1px solid #f1f1f1;
  padding: 48px;
  margin: 36px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 36px;
  border-bottom: 1px solid #f1f1f1;
}

.shopPage .bookInfo .bookInfoInside .info .key {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #888888;
  margin-bottom: 8px;
}

.shopPage .bookInfo .bookInfoInside .info .value {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #25282e;
}

.shopPage .bookDesc {
  padding: 0 2.8rem 0 2.8rem;
  margin-bottom: 44.5px;
}

.shopPage .bookDesc p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #25282e;
}

.shopPage .shopDetailsPubs {
  padding: 0 0 0 2.8rem;
  margin-top: 0 !important;
}

.shopPage .shopDetailsPubs .btns {
  display: flex;
  column-gap: 9px;
  padding-right: 2.8rem;
}

.shopPage .shopDetailsPubs .btns svg {
  cursor: pointer;
}

.shopPage .shopDetailsPubs .shopPubs {
  display: flex;
  column-gap: 0;
  overflow-x: auto !important;
}

.shopPage .shopDetailsPubs .shopPubs::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.shopPage .shopDetailsPubs .shopPubs .shopPubsCard {
  margin-right: 24px;
}

.line {
  width: 100%;
  height: 0;
  border: 1px solid #f1f1f1;
}

.shoppingCartPage .shoppingCart {
  display: flex;
  column-gap: 26px;
  margin-top: 57px;
}

.shoppingCartPage .shoppingCart .leftSide {
  width: 65%;
  display: flex;
  flex-direction: column;
  row-gap: 34px;
}

.shoppingCartPage .shoppingCart .leftSide .title {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 140%;
  color: #25282e;
}

.shoppingCartPage .shoppingCart .leftSide .subText {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #888888;
}

.shoppingCartPage .shoppingCart .leftSide .product {
  display: flex;
  column-gap: 24px;
}

.shoppingCartPage .shoppingCart .leftSide .product .imgDiv {
  width: 138px;
  height: 138px;
  background: #f2f2f2;
}

.shoppingCartPage .shoppingCart .leftSide .product .imgDiv img {
  height: 100%;
}

.shoppingCartPage .shoppingCart .leftSide .product .basket {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  width: 75%;
}

.shoppingCartPage .shoppingCart .leftSide .product .basket .productName {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #25282e;
}

.shoppingCartPage .shoppingCart .leftSide .product .basket .selectingPiece {
  display: flex;
  justify-content: space-between;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .left {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .left
  .key {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #888888;
  margin-bottom: 12px;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .left
  .value {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #25282e;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .left
  .counter {
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .left
  .counter
  button {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  color: #25282e;
  padding: 0 8px;
  font-size: 16px;
  cursor: pointer;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .right {
  display: flex;
  align-items: flex-end;
}

.shoppingCartPage
  .shoppingCart
  .leftSide
  .product
  .basket
  .selectingPiece
  .right
  a {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #25282e;
}

.shoppingCartPage .shoppingCart .rightSide {
  width: 415px;
}

.shoppingCartPage .shoppingCart .rightSide .title {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 120%;
  color: #25282e;
  margin-bottom: 24px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingCountry {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingCountry p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #888888;
}

.shoppingCartPage .shoppingCart .rightSide .shippingCountry select {
  padding: 12px 16px;
  height: 48px;
  background: #f7f7f7;
  border: 1px solid #f2f2f2;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod {
  margin-top: 24px;
  display: none;
  flex-direction: column;
  row-gap: 8px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod p:first-child {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #888888;
  margin-bottom: 8px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .selectedMethod {
  border: 1px solid #333944;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .unselectedMethod {
  border: 1px solid #f1f1f1;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .method {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .method .left {
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .method .left input {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 1px #f1f1f1;
  box-shadow: inset 0 0 0 1.5px #f1f1f1;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
}

.shoppingCartPage
  .shoppingCart
  .rightSide
  .shippingMethod
  .method
  .left
  input:focus {
  outline: none;
}

.shoppingCartPage
  .shoppingCart
  .rightSide
  .shippingMethod
  .method
  .left
  input:checked {
  box-shadow: inset 0 0 0 6px #333944;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .method .left .desc {
  display: flex;
  flex-direction: column;
}

.shoppingCartPage
  .shoppingCart
  .rightSide
  .shippingMethod
  .method
  .left
  .desc
  p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #25282e;
}

.shoppingCartPage
  .shoppingCart
  .rightSide
  .shippingMethod
  .method
  .left
  .desc
  span {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #888888;
}

.shoppingCartPage .shoppingCart .rightSide .shippingMethod .method .right p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #25282e;
}

.shoppingCartPage .shoppingCart .rightSide .summary h2 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 21px;
  line-height: 140%;
  color: #25282e;
  margin-top: 50px;
  margin-bottom: 24px;
}

.shoppingCartPage .shoppingCart .rightSide .summary div {
  margin-bottom: 8px;
}

.shoppingCartPage .shoppingCart .rightSide .summary div span {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #888888;
}

.shoppingCartPage .shoppingCart .rightSide .summary div p {
  margin-top: 8px;
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 24px;
  line-height: 21px;
  color: #25282e;
}

.shoppingCartPage .shoppingCart .rightSide .btnYellow {
  width: 100%;
  margin-top: 16px;
}

.shoppingCartPage .shoppingCart .rightSide .btnWhite {
  margin-top: 24px;
  width: 100%;
}

.shoppingCartPage .shoppingCart .rightSide .btnWhite:hover {
  background-color: #fff;
}

.shoppingCartPage .shoppingCart .rightSide .secondTitle {
  margin-top: 24px;
}

.shoppingCartPage .shoppingCart .rightSide .inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.shoppingCartPage .shoppingCart .rightSide .inputField span {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #888888;
}

.shoppingCartPage .shoppingCart .rightSide .inputField input {
  margin-top: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  background: #f7f7f7;
  border: 1px solid #f2f2f2;
}

.shoppingCartPage .shoppingCart .rightSide .inputField input:focus {
  outline: 0;
}

.footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  padding: 57px 52.5px;
  background: #f1f1f1;
  border: 1px solid #e8e8e8;
  position: relative;
  margin-top: 150px;
  min-height: 397px;
}

.footer.shop {
  background: #25282e;
  border: 0;
}

.footer.marg {
  margin-top: 250px;
}

.footer .div1 {
  display: flex;
  flex-direction: column;
}

.footer.shop .div1 {
  justify-content: space-between;
}

.footer.shop .socialMediaIcons {
  margin: 24px 0 0 0 !important;
}

.footer .div1 .socialMediaIcons {
  display: flex;
  margin: 44px 0;
}

.footer .div1 .socialMediaIcons svg {
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.footer .div1 .socialMediaIcons svg:hover {
  scale: 1.1;
}

.footer .div1 .logo {
  margin-top: 16px;
}

.footer .div1 p {
  font-size: 16px !important;
}

.footer .div1 a {
  margin-right: 11px;
  font-size: 15px;
  font-family: "Apercu Pro";
  font-weight: 400;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
}

.footer .div2 a {
  font-size: 16px !important;
  line-height: 20px !important;
}

.newsletterPage {
  width: 100%;
  height: 100vh;
}

.newsletterPage,
.newsletterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletterContainer {
  flex-direction: column;
  width: 30%;
  gap: 10px;
}

.newsletterContainer p {
  color: #888;
  font-family: Apercu Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
}

.newsletterContainer .newsletterInput {
  background-color: initial;
  border: 1px solid #888;
  margin-bottom: 8px;
  padding: 12px 16px;
  width: 100%;
  margin-top: 24px;
}

@media (max-width: 750px) {
  .newsletterContainer .newsletterInput,
  .newsletterContainer p {
    width: auto !important;
    min-width: 300px !important;
  }
}

.newsletterContainer button {
  width: 100%;
}

.footer .div4 input,
.footer .div4 button {
  width: 100%;
  height: 48px !important;
}

.footer .div4 input {
  margin-top: 24px;
  margin-bottom: 8px;
  padding: 12px 16px;
  border: 1px solid #888888;
  background-color: transparent;
}

.footer .div4 input:focus {
  outline: none;
}

.footer .div4.footerCol ul li {
  margin: 0;
}

.footer .footerCol {
  width: 100%;
}

.footer .footerCol h3 {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  color: #25282e;
  margin-bottom: 24px;
}

.footer.shop .footerCol h3 {
  color: #fff;
}

.footer .footerCol p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}

.footer .footerCol.div4 p {
  width: 100%;
}

.footer .footerCol ul {
  list-style: none;
}

.footer .footerCol ul li {
  margin-bottom: 15px;
}

.footer .footerCol ul li a {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer .footerCol ul li a:hover {
  color: #636363;
  padding-left: 5px;
}

@media (max-width: 1706px) {
  .selectedWorks .works,
  .aboutPage .teamDiv .team {
    grid-template-columns: repeat(auto-fill, 330px);
  }
}

@media (max-width: 1720px) {
  .aboutPage .contactDiv .contactUs {
    width: 65vw;
  }
}

@media (max-width: 1570px) {
  .detailContainer img {
    background-color: #f2f2f2;
    margin-right: 80px;
  }
  .aboutPage .contactDiv .contactUs {
    width: 60vw;
  }
  .modal {
    padding-top: 170px !important;
  }
  .modal .modalContent {
    width: 900px !important;
    height: 500px !important;
  }
  .modal .modalContent .modalBody {
    height: 80%;
  }
  .modal .modalContent .modalBody img {
    height: 100%;
  }
}

@media (max-width: 1516px) {
  .exhibitionsContainer .exhibitions.noneClass {
    grid-template-columns: repeat(auto-fill, 425px);
  }
}

@media (max-width: 1430px) {
  .header .insideMenu {
    margin-left: 100px;
  }
  .biography div {
    width: 85%;
  }
  .publicationDetail ul li {
    max-width: 350px;
  }
}

@media (max-width: 1426px) {
  .selectedWorks .works.noneClass .workCard:nth-child(n + 7) {
    display: none;
  }
}

@media (max-width: 1414px) {
  .fairsPageContainer .fairs {
    justify-content: space-around;
  }
}

@media (max-width: 1381px) {
  .exhibitionsContainer .exhibitions.noneClass {
    justify-content: space-around;
  }
  .exhibitionsContainer
    .exhibitions.noneClass
    .exhibitionCard:nth-child(n + 5) {
    display: none;
  }
  .newsPageContainer .news .news-card {
    width: 390px !important;
  }
}

@media (max-width: 1360px) {
  .aboutPage .contactDiv .contactUs {
    width: 55vw;
  }
  .shop .footerLogo {
    width: 260px !important;
  }
}

@media (max-width: 1296px) {
  .shopPage .shopDetailBanner .rightSide .description,
  .shopPage .shopDetailBanner .rightSide .title {
    width: 100% !important;
  }
  .aboutPage .about {
    padding: 0px 10rem;
  }
  .bannerContainer {
    flex-direction: column;
  }
  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 0px;
  }
  .header .insideMenu {
    margin-left: 50px;
  }
  .publicationDetail ul li {
    max-width: 300px;
  }
  .newsPageContainer .news .news-card {
    width: 360px !important;
  }
}

@media (max-width: 1204px) {
  .shopPage .shopBanner .leftSide {
    height: 450px;
  }
  .aboutPage .contactDiv .contactUs {
    width: 50vw;
  }
  .exhibitionOverview {
    flex-direction: column;
  }
  .exhibitionOverview .leftSide h1 {
    margin-top: 20px;
  }
  .exhibitionOverview .leftSide h2,
  .exhibitionOverview .leftSide .date,
  .exhibitionOverview .leftSide a {
    margin-bottom: 20px;
  }
  .exhibitionOverview .rightSide img {
    width: 100%;
    height: 100%;
  }
  .detailContainer {
    flex-direction: column;
  }
  .detailContainer img {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .detailContainer div {
    width: 80%;
  }
  .newsPageContainer .news .news-card {
    width: 320px !important;
  }
}

@media (max-width: 1200px) {
  .footerCol {
    margin-bottom: 30px;
  }
  .header .insideMenu {
    margin-left: 0;
  }
  .shop .footerLogo {
    width: 309px !important;
  }
}

@media (max-width: 1170px) {
  .buttons {
    flex-wrap: wrap;
  }
  .buttons > * {
    margin: 0 auto;
  }
  .gallery {
    height: 580px;
  }
  .gallery .content .slide img {
    width: 0;
    height: 0;
  }
  .gallery .content,
  .gallery .content .slide.active img {
    width: 880px;
    height: 464.38px;
  }
  .fairsDetailContainer .introduce p {
    width: 70%;
  }
  .newsDetailPage {
    margin-top: 6rem;
  }
  .newsDetailPage .newsDetail {
    flex-direction: column;
  }
  .newsDetailPage .newsDetail .leftSide {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .newsDetailPage .newsDetail .rightSide {
    margin-left: 0;
  }
}

@media (max-width: 1100px) {
  .header.shopHeader .none {
    display: block;
  }

  .header {
    padding: 16px;
  }
  .header .fixedMenu {
    position: fixed;
    z-index: 9;
    margin-right: 0;
    left: 0;
    right: 0;
    top: 76px;
    bottom: 100%;
    width: auto;
    height: auto;
    flex-direction: column;
    background-color: white;
    overflow: scroll;
    transition: all 0.5s ease-in-out;
  }
  .header .fixedMenu::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #e2e2e2;
    border-radius: 6px;
  }
  .header .fixedMenu::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 6px;
  }
  .header .insideMenu {
    margin: 0;
  }
  .header .dropdown {
    display: none !important;
  }
  .header .socialMediaIcons {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 !important;
  }
  .header .socialMediaIcons svg {
    margin: 0 10px !important;
  }
  .header ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0;
  }
  .header ul li a,
  .header ul li .followDropdown {
    color: #25282e;
  }
  .header ul li:last-child {
    margin-bottom: 40px;
  }
  .header ul li a {
    font-size: 22px;
  }
  .header .iconBurger {
    display: block;
  }
  .header :checked ~ ul {
    bottom: 0;
  }
  .header svg {
    justify-content: center;
  }
  .fairsContainer .rightSide {
    padding: 10px 30px;
  }
  .fairsContainer .fairs .fairCard {
    flex-direction: column;
    height: 100%;
  }
  .fairsContainer .fairs .fairCard a img {
    width: 100%;
  }
}

@media (max-width: 1096px) {
  .selectedWorks .works.noneClass {
    justify-content: space-around;
  }
  .selectedWorks .works.noneClass .workCard:nth-child(n + 5) {
    display: none;
  }
  .newsPageContainer .news .news-card {
    width: 290px !important;
  }
}

@media (max-width: 1066px) {
  .modal .modalContent {
    width: 600px !important;
    height: 350px !important;
  }
  .modal .modalContent .modalBody {
    height: 70%;
  }
  .modal .modalContent .modalBody img {
    height: 100%;
  }
  .publicationDetail {
    height: 600px;
  }
  .publicationsContainer .publications.noneClass {
    justify-content: space-around;
  }
  .publicationsContainer
    .publications.noneClass
    .publicationCard:nth-child(n + 5) {
    display: none;
  }
  .shopPage .shopSlider .product .leftSide {
    width: 100%;
  }
  .shopPage .shopSlider .product .leftSide .names .productName.active {
    width: 350px !important;
  }
  .shopPage .shopSlider .product .rightSide {
    width: 100%;
    height: 400px !important;
  }
}

@media (max-width: 1046px) {
  .exhibitionsContainer .exhibitions {
    justify-content: center;
  }
  .exhibitionsContainer
    .exhibitions.noneClass
    .exhibitionCard:nth-child(n + 3) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .shoppingCart {
    flex-direction: column;
  }
  .shoppingCart .leftSide {
    width: 100% !important;
  }
  .shoppingCart .rightSide {
    margin: 70px auto 0 auto;
  }
  .newsPageContainer .news {
    flex-direction: column;
  }
  .newsPageContainer .news .news-card {
    width: 500px !important;
  }
  .aboutPage .contactDiv {
    flex-direction: column;
  }
  .aboutPage .contactDiv img {
    width: 416px;
    height: 416px;
  }
  .aboutPage .contactDiv .contactUs {
    width: 100%;
    position: static;
    padding: 14px 0 38px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .shopPage .shopSlider .product {
    flex-direction: column-reverse;
  }
  .shopPage .shopSlider .product .leftSide {
    row-gap: 20px;
  }
  .shopPage .shopSlider .product .leftSide .names .productName.active {
    min-height: 150px !important;
  }
}

@media (max-width: 962px) {
  .shopPage:not(.shopDetailsPage) .shopPubsField .shopPubs {
    justify-content: center !important;
  }
  .shopPage .shopDetailBanner {
    flex-direction: column;
    margin-top: 30px;
  }
  .shopPage .shopDetailBanner .leftSide {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
  }
  .shopPage .shopDetailBanner .rightSide {
    width: 100%;
    padding: 0;
  }
  .shopPage .shopDetailBanner .rightSide .title {
    width: 100%;
  }
}

@media (max-width: 950px) {
  .biography div {
    flex-direction: column-reverse;
    width: 100%;
  }
  .biogra phy div .bioText {
    margin-top: 25px;
  }
  .biography div .pictureField img {
    width: 260px;
  }
  .exhibitionsContainer .exhibitions.exhibitionList .exhibitionCard {
    margin: 0 auto 40px auto;
  }
  .gallery {
    height: 460px;
  }
  .gallery .content .slide img {
    width: 0;
    height: 0;
  }
  .gallery .content,
  .gallery .content .slide.active img {
    width: 663.25px;
    height: 350px;
  }
}

@media (max-width: 900px) {
  .shopPage .shopBanner {
    flex-direction: column;
    row-gap: 16px;
  }
  .shopPage .shopBanner .rightSide {
    padding-left: 0 !important;
    width: 100%;
    align-items: center;
  }
  .shopPage .shopBanner .leftSide {
    width: 100%;
  }
  .shopPage .shopBanner .leftSide img {
    margin: 0 auto;
  }
}

@media (max-width: 845px) {
  .shopPage .shopPubsField {
    margin-top: 140px !important;
  }
}

@media (max-width: 838px) {
  .aboutPage .about {
    padding: 0 6rem;
  }
  .fairsDetailContainer .introduce {
    flex-direction: column;
  }
  .fairsDetailContainer .introduce p {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 820px) {
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 10px 25px;
  }
  .img-slider .slide .info h2 {
    font-size: 35px;
  }
  .img-slider .slide .info p {
    width: 70%;
    font-size: 15px;
  }
  .img-slider .navigation {
    bottom: 25px;
  }
  .img-slider .navigation .btn {
    width: 10px;
    height: 10px;
    margin: 8px;
  }
  .searchResultField .result {
    padding: 40px 0px 40px 0px !important;
    column-gap: 0 !important;
  }
  .searchResultField .result .resultTitle {
    width: 160px !important;
    font-size: 18px !important;
  }
  .searchResultField .result .resultItem {
    font-size: 18px !important;
  }
}

@media (max-width: 766px) {
  .selectedWorks .works.noneClass .workCard:nth-child(n + 3) {
    display: none;
  }
  .aboutPage .teamDiv .team {
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .searchPage .exhibitionsContainer {
    padding: 96px 0 0px 0 !important;
  }
  .searchPage .fairsPageContainer {
    padding: 50px 0rem 100px 0rem !important;
    margin-top: 0;
  }
  .searchPage .titles,
  .searchPage .artistResults .artistsList {
    justify-content: center;
  }
  .searchPage .artistsCard,
  .searchPage .exhibitionCard,
  .searchPage .fairCard,
  .searchPage .publicationCard,
  .searchPage .news-card {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .newsDetailPage .newsDetail .leftSide img {
    width: 504px;
    height: 420px;
  }
  .fairsDetailContainer .introduceImage {
    display: none;
  }
  .fairsPageContainer .head div:first-child {
    flex: 0;
  }
  .fairsPageContainer .head h2 {
    text-align: start;
  }
  .fairsPageContainer .fairs .fairCard {
    width: 382.822px;
    height: 300px;
  }
  .gallery {
    height: 420px;
  }
  .gallery .content .slide img {
    width: 0;
    height: 0;
  }
  .gallery .content,
  .gallery .content .slide.active img {
    width: 600px;
    height: 316.62px;
  }
  .bannerContainer.publicationBanner img {
    width: 100%;
  }
  .footerCol {
    width: 100% !important;
    text-align: center;
  }
  .footer .footerCol p {
    width: 100%;
  }
  .footer .div1 .svgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .footer .div1 svg {
    margin: 0 auto;
  }
  .newsContainer .news {
    padding: 0 0 50px 0;
  }
  .newsContainer.shop .news {
    padding: 50px 0 50px 0;
  }
  .fairsContainer h2 {
    padding: 0;
    padding-left: 0 !important;
  }
  .newsContainer .newsHeader {
    padding: 0 16px 0 0 !important;
  }
  .exhibitionsContainer div .main-exhibition {
    margin-bottom: 28px !important;
  }
  .newsCard {
    padding: 0 !important;
    margin: 0 18px;
  }
  .newsCard .title,
  .newsCard .description {
    padding: 0 16px;
  }
  .exhibitionsContainer {
    padding: 96px 0 74px 0;
  }
  .exhibitionsContainer h2 {
    text-align: center;
  }
  .exhibitionsContainer .exhibitions {
    grid-template-columns: repeat(auto-fill, 400px);
    margin-bottom: 0px;
  }
  .exhibitionsContainer .exhibitions .exhibitionCard {
    width: 340px;
    margin-bottom: 0;
  }
  .exhibitionsContainer .exhibitions .exhibitionCard .image {
    height: 220px;
    width: 340px;
  }
  .publicationsContainer
    .publications.noneClass
    .publicationCard:nth-child(n + 3) {
    display: none;
  }
  .publicationsContainer .publications {
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: center;
  }
  .exhibitionsContainer .publicationsContainer {
    margin-top: 150px;
    padding: 0;
  }
  .publicationsContainer.publicationsDetailContainer {
    margin: 2rem 0;
    padding: 0;
  }
  .pressContainer {
    padding: 96px 1rem 74px 1rem;
  }
  .pressContainer .rightSide {
    display: flex;
    flex-direction: column;
  }
  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 40px;
    width: 100%;
  }
  .publicationDetail {
    height: 820px;
    padding: 37px 40px;
  }
  .publicationDetail ul {
    column-count: 2;
  }
  .publicationDetail ul li {
    max-width: 200px;
  }
}

@media (max-width: 648px) {
  .modal {
    padding-top: 220px !important;
  }
  .modal .modalContent {
    width: 350px !important;
    height: 550px !important;
  }
  .modal .modalContent .modalBody {
    height: 70%;
    flex-direction: column;
    row-gap: 20px;
  }
  .modal .modalContent .modalBody img {
    height: 70%;
  }
  .shopPage .shopDetailBanner .leftSide {
    height: 390px;
  }
  .shopPage .shopPubsField .top select {
    width: 220px !important;
  }
  .aboutPage .about {
    padding: 0 4rem;
  }
  .aboutPage .contactDiv img {
    width: 100%;
    height: 100%;
  }
  .gallery {
    height: 350px;
  }
  .gallery .content .slide img {
    width: 0;
    height: 0;
  }
  .gallery .content,
  .gallery .content .slide.active img {
    width: 450px;
    height: 237.46px;
  }
  .footer .div1 .socialMediaIcons,
  .bannerContainer {
    padding: 0;
  }
  .bannerContainer img {
    width: 100%;
    object-fit: cover;
  }
  .bannerContainer .rightSide {
    width: 100%;
  }
  .bannerContainer .rightSide .title {
    font-size: 32px;
    line-height: 30px;
  }
  .bannerContainer .rightSide .description {
    font-size: 16px;
    line-height: 20px;
  }
  .artistsContainer .artists {
    display: none;
  }
  .artistsContainer .namesDiv,
  .fairsDetailContainer .namesDiv {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    padding: 0 10px;
  }
  .artistsContainer .namesDiv .namesColFor2Columns,
  .fairsDetailContainer .namesDiv .namesColFor2Columns {
    display: block;
  }
  .artistsContainer .namesDiv .namesColFor3Columns,
  .fairsDetailContainer .namesDiv .namesColFor3Columns {
    display: none;
  }
  .artistsContainer .namesDiv div,
  .fairsDetailContainer .namesDiv div {
    width: 50%;
  }
  .subMenu {
    overflow-y: auto;
  }
  .subMenu::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .subMenu li {
    margin-left: 22px;
  }
  .subMenu li a {
    font-size: 13px;
    line-height: 28px;
  }
  .subMenu li:nth-last-of-type(1) {
    margin-right: 22px;
  }
  .bannerContainer .rightSide .title,
  .bannerContainer .rightSide .description {
    width: 100%;
  }
  .bannerContainer.publicationBanner .rightSide {
    padding: 0 20px 0 20px;
  }
  .detailContainer {
    padding: 0 40px;
  }
  .detailContainer img,
  .detailContainer div {
    width: 100%;
  }

  .fairsContainer .fairs .fairCard:hover a img {
    opacity: 0.5;
  }

  .fairsContainer .fairs .fairCard a {
    width: 95% !important;
  }
  .fairsContainer .fairs .fairCard a img {
    object-fit: cover !important;
  }
  .fairsContainer .fairs .fairCard a .rightSide {
    display: none;
  }
  .fairsContainer .fairs .fairCard:hover a .rightSide,
  .fairsContainer .fairs .fairCard:hover a .rightSide {
    position: absolute;
    display: flex;
  }
}

@media (max-width: 620px) {
  .shopDetailsPubs .top h3 {
    max-width: 300px !important;
    text-align: start !important;
    overflow-wrap: break-word !important;
  }
  .newsDetailPage .newsDetail .leftSide img {
    width: 420px;
    height: 350px;
  }
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 10px 20px;
  }
  .img-slider .slide .info h2 {
    font-size: 30px;
  }
  .img-slider .slide .info p {
    width: 80%;
    font-size: 13px;
  }
  .img-slider .navigation {
    bottom: 15px;
  }
  .img-slider .navigation .btn {
    width: 8px;
    height: 8px;
    margin: 6px;
  }
  .bannerContainer.publicationBanner img {
    height: 500px;
  }
  .newsPageContainer .news .news-card {
    width: 390px !important;
  }
  .aboutPage .locationDiv {
    padding: 0;
  }
  .aboutPage .locationDiv h4 {
    text-align: center;
  }
  .aboutPage .locationDiv .locations {
    justify-content: center;
  }
  .container .img-slider #logo2 {
    display: none !important;
  }
  .header #logo1 {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .shoppingCart .leftSide .selectingPiece {
    flex-direction: column;
  }
  .shoppingCart .leftSide .selectingPiece .left {
    flex-direction: column;
    row-gap: 16px;
  }
  .shoppingCart .leftSide .selectingPiece .left .quantity,
  .shoppingCart .leftSide .selectingPiece .left .price,
  .shoppingCart .leftSide .selectingPiece .left .total {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .shoppingCart .leftSide .selectingPiece .left .quantity p,
  .shoppingCart .leftSide .selectingPiece .left .price p,
  .shoppingCart .leftSide .selectingPiece .left .total p {
    margin-bottom: 0 !important;
  }
  .shoppingCart .leftSide .selectingPiece .right {
    align-self: center;
    margin-top: 12px;
  }
  .shoppingCart .rightSide {
    width: 380px !important;
  }
}

@media (max-width: 550px) {
  .shopPage .shopBanner .leftSide img {
    height: 400px !important;
  }
  .searchPopup .searchField input {
    flex: 8;
    font-family: "Apercu Pro";
    font-weight: 700;
    font-size: 1rem;
    line-height: 120%;
    color: #25282e;
    padding: 16px 0;
  }
  .searchPopup .searchField input,
  .searchPopup .searchField input:focus {
    border: none;
    outline: none;
  }
  .searchPopup .searchField input::placeholder {
    font-family: "Apercu Pro";
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #888888;
  }
  .searchPopup .searchField {
    padding: 16px 16px;
  }
}

@media (max-width: 500px) {
  /* .newsHead {
    padding: 50px 2.8rem 65px 2.8rem !important;
  } */
  .aboutPage .about {
    padding: 0 2rem;
  }
  .newsDetailPage {
    margin-top: 6rem;
  }
  .newsDetailPage .newsContainer {
    height: auto;
    margin-top: 24px;
  }
  .newsDetailPage .newsDetail {
    padding: 0 2rem 0 2rem;
  }
  .newsDetailPage .newsDetail .leftSide img {
    width: 360px;
    height: 300px;
  }
  .newsCard {
    min-width: 94vw !important;
    margin: 0 24px 0 8px !important;
  }
  .newsContainer .newsCard.shopCard {
    min-width: 500px !important;
  }
  .aboutPage .contactDiv .contactUs {
    height: 372px;
  }
  .aboutPage .contactDiv .contactUs h3 {
    margin-bottom: 24px;
  }
}

@media (max-width: 420px) {
  .shoppingCart .rightSide {
    width: 310px !important;
  }
  .shoppingCart .leftSide .counter {
    column-gap: 6px !important;
  }
  .shopModal .shopModalContent {
    width: 350px !important;
  }
  .shopDetailsPubs {
    padding: 0 0 0 8px !important;
  }
  .shopDetailsPubs .btns {
    padding-right: 8px !important;
  }
  .shopPage .shopPubsField .top select {
    width: 175px !important;
  }
  .shopPage .shopBanner {
    row-gap: 0;
  }
  .shopPage .shopBanner .leftSide img {
    height: 300px !important;
  }
  .shopHeader .headerLogo {
    width: 270px !important;
  }
  .shopPage .shopSlider .product .leftSide .names .productName.active {
    width: 300px !important;
    min-height: 190px !important;
  }
  .shopPage .shopPubsField .top h3 {
    font-size: 26px;
  }
  .shopPage .shopPubsField .shopPubs {
    grid-template-columns: repeat(auto-fill, 317px);
  }
  .newsContainer .newsHeader .iconDiv {
    height: 35px !important;
    width: 35px !important;
  }
  .aboutPage .locationDiv .locations {
    grid-template-columns: repeat(auto-fill, 303px);
  }
  .aboutPage .locationDiv .locations .location img {
    width: 303.4px;
    height: 223.18679px;
  }
  .fairsPageContainer .fairs .fairCard {
    width: 319.01px;
    height: 250px;
  }
  .newsDetailPage .newsDetail {
    padding: 0 1rem 0 1rem;
  }
  /* .newsHead {
    padding: 30px 2.8rem 65px 2.8rem !important;
  } */
  .newsPageContainer .news .news-card {
    width: 280px !important;
  }
  .footer .div1 .socialMediaIcons {
    padding: 0 !important;
  }
  .footer .div4 input,
  .footer .div4 button {
    width: 220px;
  }
  .publicationDetail {
    height: 900px;
  }
  .artistsContainer .namesDiv li a:hover ~ img,
  .fairsDetailContainer .namesDiv li a:hover ~ img {
    width: 140px !important;
  }
  .gallery {
    height: 270px;
  }
  .gallery .content .slide img {
    width: 0;
    height: 0;
  }
  .gallery .content,
  .gallery .content .slide.active img {
    width: 320px;
    height: 168.865px;
  }
  .gallery .backDisabled,
  .gallery .backActived {
    right: 50px !important;
    bottom: 5px !important;
    scale: 0.7;
  }
  .gallery .nextDisabled,
  .gallery .nextActived {
    right: 5px !important;
    bottom: 5px !important;
    scale: 0.7;
  }
}

@media (max-width: 360px) {
  .img-slider {
    width: 100%;
  }
  .img-slider .slide .info {
    padding: 5px 10px;
  }
  .img-slider .slide .info h2 {
    font-size: 25px;
  }
  .img-slider .slide .info p {
    width: 90%;
    font-size: 11px;
  }
  .img-slider .navigation {
    bottom: 10px;
  }
}

.footer .policies h4 {
  font-family: "Apercu Pro";
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  color: #25282e;
  margin-bottom: 5px;
}

.footer .policies {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .policies ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  gap: 20px;
}

.footer .policies ul li {
  margin-bottom: 10px;
  text-align: center;
}

.footer .policies ul li a {
  font-family: "Apercu Pro";
  font-weight: 300;
  font-size: 12px;
  line-height: 10px;
  color: #888888;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
}

.footer .policies p {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #888888;
}

@media (max-width: 900px) {
  .footer .policies {
    grid-column: 1;
  }
  .footer .policies,
  .footer .policies ul {
    display: flex;
    flex-direction: column;
  }
  .footer .policies ul {
    list-style: none;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
    gap: 10px;
  }
}

/*# sourceMappingURL=style.css.map */
