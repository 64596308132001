@media (max-width: 1100px) {
  .header .fixedMenu {
    position: fixed;
    z-index: 9;
    margin-right: 0;
    left: 0;
    right: 0;
    top: 76px;
    bottom: 100%;
    width: auto;
    height: 100%;
    flex-direction: column;
    background-color: white;
    overflow: scroll;
    transition: all 0.5s ease-in-out;
  }
}

@media (max-width: 1300px) {
  .aboutPage .teamDiv .team {
    grid-template-columns: auto auto auto !important;
  }

  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer div:last-child {
    grid-column: 1 / -1;
  }
}

@media (max-width: 750px) {
  .socialMediaIcons {
    justify-content: center !important;
  }
  .newsCard {
    min-width: 1vw !important;
  }
  .newsCard,
  .fairsContainer .fairs .fairCard {
    margin: 0 !important;
  }
  .newsDetailPage .newsDetail .rightSide {
    padding: 5% !important;
  }
  .newsContainer .newsCard.shopCard {
    display: flex !important;
    flex-direction: column !important;
    min-width: unset !important;
  }
  .exhibitionCard,
  .exhibitions,
  .exhibitionsContainer .main-exhibition {
    margin: 8px !important;
  }

  .footer {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .biography > div {
    width: 90%;
  }

  .biography > div .bioText h1 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    color: #25282e;
    word-spacing: 100vw;
  }

  .exhibitionOverview {
    padding: 72px 1rem 48px;
  }

  .fairsPageContainer .head div:last-child {
    width: 100% !important;
    flex: 1 1 !important;
  }

  .publicationsContainer-few .publications {
    grid-template-columns: auto !important;
  }

  .fairsPageContainer .head h2 {
    margin-bottom: 1rem !important;
    margin-left: unset !important;
    text-align: center !important;
  }

  .fairsPageContainer > div {
    padding: 1rem !important;
  }

  .exhibitionsContainer > div,
  .news-list > div {
    padding: 0 !important;
  }

  /* .exhibitionOverview {
    padding-top: 120px !important;
  } */

  .publicationsContainer .publications {
    grid-template-columns: auto !important;
  }

  .btnWhite,
  .btnYellow {
    width: auto !important;
    min-width: 200px !important;
  }

  .aboutPage .about {
    padding: 1rem 2rem !important;
  }

  .aboutPage .teamDiv .team,
  .aboutPage .locationDiv .locations {
    grid-template-columns: auto !important;
  }

  .aboutPage .locationDiv .locations .location {
    padding: 1rem !important;
  }

  #map {
    width: 100% !important;
  }

  .logohref,
  .svghref {
    cursor: pointer !important;
  }

  #logo1 {
    width: 85% !important;
    text-align: -webkit-center !important;
    cursor: pointer;
  }

  /* .header-web {
    display: none !important;
  } */

  .header-mobile {
    display: flex !important;
  }

  .fixedMenu li .active {
    font-size: xx-large !important;
    font-weight: bold !important;
  }

  .artistlistskeleton {
    grid-template-columns: auto !important;
  }

  .exhibitionsContainer {
    padding: 2rem 1rem !important;
  }

  .exhibitions,
  .exhibitionsContainer .main-exhibition,
  .exhibitionCard {
    margin: 0 !important;
  }

  .customFair .swiper-slide {
    width: 350px !important;
    height: auto !important;

    right: 33px;
  }

  .exhibitionsContainer .buttons {
    overflow: scroll !important;
    justify-content: start !important;
  }

  .magni-element {
    width: 95% !important;
  }

  .selectedWorks .works {
    gap: "1rem" !important;
    grid-template-columns: auto !important;
  }

  .selectedWorks .workCard {
    width: unset !important;
  }

  .selectedWorks .workCard img {
    width: 306px !important;
  }

  .ReactModal__Content--after-open {
    width: 100% !important;
    transform: translate(-50%, -35%) !important;
  }

  .ReactModalPortal .modalContent .modalBody {
    flex-wrap: wrap !important;
    gap: 1rem !important;
  }

  .ReactModalPortal .modalContent .modalBody img {
    width: -webkit-fill-available !important;
  }

  .ReactModalPortal .modalFooter {
    margin-top: 1rem !important;
    justify-content: center !important;
  }

  .ReactModal__Content {
    height: unset !important;
  }

  .biography {
    margin: 2rem 0;
  }

  .subMenuDiv .subMenu {
    margin: 0 !important;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 8px 32px;
  }

  .subMenuDiv .subMenu li {
    margin: 0 !important;
  }

  .exhibitionsContainer .exhibitions,
  .exhibitionsContainer .exhibitionsList {
    grid-template-columns: auto !important;
  }

  .fairsPageContainer .head,
  .fairsPageContainer .head {
    flex-direction: column !important;
  }

  .fairsPageContainer .head div:last-child {
    overflow: scroll !important;
    width: 100% !important;
    display: block !important;
  }

  .fairsPageContainer .fairs {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }

  .fairCard,
  .biography div .pictureField img,
  .biography .pictureField,
  .biography .pictureField div:first-child {
    width: 100% !important;
  }
}

img {
  object-fit: cover !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.cardlink {
  color: #000000;
}

.container {
  position: unset;
  top: unset;
  margin-top: 76px;
}

.footer {
  margin-top: 0;
}

.newsContainer .newsCard.shopCard {
  max-width: 100%;
  justify-content: left;
}

.news-card h4 {
  font-size: large;
  padding: 0.5rem 0;
}

.news-card p {
  font-size: small;
}

.news-card img {
  cursor: pointer;
}

.newsHead .btnWhite,
.newsHead .btnYellow {
  width: 140px;
  font-size: smaller;
}

.modalFooter .exhibitionOverview .rightSide img {
  object-fit: contain;
}

.fairsDetailContainer .introduce {
  padding: 72px 2% 48px 10.8%;
}

.fairsDetailContainer .introduce h4 {
  font-size: 22px;
}

#overview {
  padding: 2rem 1rem;
}

.header-web {
  display: flex;
}

.header-mobile {
  display: none;
}

.header ul {
  margin-right: 0;
  margin-left: auto;
}

.caroselimages {
  height: 772px !important;
  width: 100%;
}

button:disabled {
  opacity: 0.5;
  cursor: unset;
  background-color: #f5f4f4;
}

button:disabled:hover {
  background-color: #f5f4f4 !important;
  border: 1px solid #e8e8e8;
}

.iconDiv {
  font-size: xx-large;
}

.iconDiv:hover {
  background-color: #fff200 !important;
}

.iconDiv.disabled:hover {
  background-color: #f5f4f4 !important;
  cursor: unset !important;
}

.iconDiv.disabled {
  opacity: 0.7 !important;
  fill: white !important;
  color: #e8e8e8 !important;
}

.control-dots {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  width: 100% !important;
  bottom: 30px !important;
  z-index: 3 !important;
}

.dot {
  box-shadow: none !important;
  height: 10px !important;
  width: 10px !important;
  background-color: transparent !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 50% !important;
  margin: 13px !important;
  cursor: pointer !important;
}

.dot.selected {
  background-color: #fff200 !important;
}

.titleField {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: auto;
  width: 100%;
  height: 90%;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.titleField p {
  font-weight: 400;
  font-size: 21px;
  margin-top: 16px;
  margin-bottom: 36px;
  text-shadow: 5px 0px 12px rgba(0, 0, 0, 0.5);
}

.titleField h2:first-child {
  font-weight: 700;
}

.titleField h2:first-child,
.titleField h2:nth-child(2) {
  font-size: 61px;
  text-shadow: 5px 0px 12px rgba(0, 0, 0, 0.5);
}

.titleField h2:nth-child(2) {
  font-weight: 500;
  margin-top: 16px;
}

.mainpage {
  position: relative;
  height: 772px;
  width: 100%;
}

.newsContainer {
  height: 100%;
}

.fairCard .description {
  overflow: hidden;
}

.exhibitionCard img {
  object-fit: contain;
}

.customFair.faircard {
  margin: 12px !important;
}

.fairCard a img {
  object-fit: contain;
}

.customFair .swiper-slide {
  width: 635px !important;
  margin-right: 12px !important;
  display: flex !important;
  height: 240px !important;
  width: 635px !important;
}

.artistsContainer .namesDiv li,
.fairsDetailContainer .namesDiv li a:hover {
  cursor: pointer;
}

.detailContainer {
  padding-top: 2rem;
}

.detailContainer img {
  max-width: 400px;
}

.newsContainer {
  padding-top: 25px !important;
}

.subMenu > li {
  cursor: pointer;
}

.publicationsContainer-few {
  padding-top: 0 !important;
}

.publicationsContainer-few h1 {
  margin-top: 0 !important;
}

.exhibitionsContainer .btnYellow {
  height: 53px;
  background: #fff200;
  font-family: "Apercu Pro";
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #25282e;
  cursor: pointer;
}

.exhibitionsContainer .btnYellow,
.exhibitionNavButton .btnWhite {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exhibitionNavButton .btnWhite {
  margin-bottom: 20px;
}

.exhibitionsContainer .exhibitions {
  /* grid-template-columns: auto auto auto; */
  justify-content: space-between;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 420px);
}

.exhibitionsContainer .exhibitionCard,
.exhibitionsContainer .exhibitionCard .image {
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.exhibitionsContainer .exhibitionCard .image img {
  object-fit: cover;
}

.exhibitionsContainer h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 120%;
  text-align: start !important;
}

.galleryNavButton {
  cursor: pointer;
  font-size: larger;
  z-index: 2;
  color: #333944;
  font-family: "Apercu Pro";
  background: white;
  padding: 0.8rem;
  line-height: 1rem;
  bottom: 1.5rem !important;
}

.galleryNavButton:hover {
  background: #fff200 !important;
}

.galleryNavButton.disabled {
  opacity: 0.7 !important;
  fill: white !important;
  color: #e8e8e8 !important;
}

.galleryNavButton.disabled:hover {
  background-color: #f5f4f4 !important;
  cursor: unset !important;
}

.gallery {
  height: 100% !important;
}

.gallery img {
  height: 595px !important;
  object-fit: contain;
}

.magni {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  align-items: center;
  height: auto;
  max-height: 600px;
}

.magni-element {
  width: 65%;
}

.magniButtons-container {
  justify-content: end;
  top: -5rem;
  width: 80%;
}

.magniButtons-container,
.magniButtons {
  display: flex;

  position: relative;
}

.magniButtons .lastSvg {
  margin-left: 1rem;
}

/* .magni .mainImg {
  max-height: 600px;
} */

.media-container {
  padding: 2rem 1rem;
  min-height: 300px;
}

.media-container h1 {
  text-align: center;
  padding: 1rem 8.5%;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
}

.teamDiv {
  min-height: 300px !important;
}

.aboutPage .about {
  padding: 2rem 11%;
  margin: 0;
}

.aboutPage .teamDiv .team {
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  gap: 1.5rem;
}

/* .biography > div {
  margin: 1rem;
} */

.contactUs p a {
  all: unset;
  cursor: pointer;
}

.contactUs p a:hover {
  font-weight: bolder !important;
}

.contactDiv {
  min-height: 300px;
}

.aboutPage .contactDiv .contactUs p {
  width: 335px !important;
}

.selectedWorks {
  max-height: unset;
  min-height: 300px;
  margin: 0;
}

.selectedWorks h1 {
  margin-bottom: 32px;
}

.selectedWorks .works {
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  justify-content: center;
}

.selectedWorks .works .workCard {
  height: unset;
  width: 22vw;
  margin: unset;
}

.selectedWorks .works .workCard div img {
  object-fit: cover;
  object-position: center;
  width: 22vw;
  height: auto;
}

.newsContainer .newsCard {
  margin: 0 30px;
  /* margin: 0 20px; */
}

.newsContainer .newsCard .description {
  margin-top: 0;
  font-size: 16px;
  color: #ffffffc9;
  margin-bottom: 10px;
}

.newsContainer .newsCard .title {
  font-size: 26px;
}

.newsContainer .newsCard {
  min-width: unset;
}

.newsContainer .newsCard img {
  object-fit: cover !important;
  max-height: 400px;
  padding: 20px;
}

.photosCol a:first-child() {
  margin-bottom: 12px;
}

.photosCol a:last-child {
  margin-top: 12px;
}

.photosCol a {
  margin: 6px;
}

.artistsContainer {
  padding-top: 1rem;
}

.artistsContainer h1 {
  margin: 1rem 0 2rem 0;
}

.artistsContainer .artists {
  justify-content: center;
}

.publicationsContainer .publications {
  grid-template-columns: repeat(auto-fill, 320px);
  /* grid-template-columns: auto auto auto auto; */
  column-gap: 1rem;
  justify-content: center;
}

.publicationsContainer .publications .publicationCard {
  margin: 0.75rem;
}

.main-exhibition {
  text-align: center !important;
}

.grid {
  display: grid;
}

.place-content-center {
  place-content: center;
}

.introduceImage {
  object-fit: cover;
}

/* .fixedMenu{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
} */

.fixedMenu li .active {
  font-weight: bold;
  font-size: large;
  color: #000000;
}

.exhibitionsContainer .buttons {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto auto auto auto;
  justify-content: start;

  width: 100%;
}

/* .exhibitionsContainer .btnWhite {
  margin-bottom: 2rem;
} */

/* .newsHead {
  padding: 0px 0px 1rem 0px;
} */

.news-card img {
  width: 100%;
  object-fit: cover;
  /* height: 376px; */
}

.newsDetailPage .newsDetail {
  min-height: 300px;
  /* padding: 0 4.8rem 0 11.8rem; */
  justify-content: center;
}

.newsDetailPage .newsContainer {
  padding: 0 4%;
}

.newsDetail img {
  object-fit: contain;
}

.newsContainer .newsHeader {
  padding: 12px 50px;
}

.artistsNews {
  padding: 0 10px 10px 10px !important;
}

.personCard img {
  object-fit: cover;
}

/* .news-even .my-masonry-grid_column:nth-child(2n) .news-card:nth-child(2n) img {
  height: 300px;
}

.news-even
  .my-masonry-grid_column:nth-child(2n)
  .news-card:nth-child(2n + 1)
  img {
  height: 500px;
}

.news-even
  .my-masonry-grid_column:nth-child(2n + 1)
  .news-card:nth-child(2n)
  img {
  height: 500px;
}

.news-even
  .my-masonry-grid_column:nth-child(2n + 1)
  .news-card:nth-child(2n + 1)
  img {
  height: 300px;
} */

/* .news-odd
  .my-masonry-grid_column:nth-child(2n)
  .news-card:nth-child(2n + 1)
  img {
  height: 300px;
}

.news-odd .my-masonry-grid_column:nth-child(2n) .news-card:nth-child(2n) img {
  height: 500px; 
} */

/* .news-odd
  .my-masonry-grid_column:nth-child(2n + 1)
  .news-card:nth-child(2n + 1)
  img {
  height: 500px; 
} */

/* .news-odd
  .my-masonry-grid_column:nth-child(2n + 1)
  .news-card:nth-child(2n)
  img {
  height: 300px;
} */

.my-masonry-grid {
  /* Not needed if autoprefixing */
  /* Not needed if autoprefixing */
  display: flex;
  /* margin-left: -2rem; */
  /* gutter size offset */
  width: auto;
  gap: 16px;
}

.my-masonry-grid_column {
  /* padding-left: 2rem; */
  /* gutter size */
  background-clip: padding-box;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

/* Style your items */

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 1rem;
}

.news-list-container {
  display: grid;
  place-items: center;
  margin: 2rem 1rem;
}

.news-list {
  padding: 2rem 1rem;
}

.news-list > div {
  padding: 0 8%;
}

.aboutPage .locationDiv {
  min-height: 300px;
}

.aboutPage .locationDiv .locations {
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 1.5rem;
}

.aboutPage .locationDiv .locations .location img {
  object-fit: cover;
  cursor: pointer;
}

.aboutPage .locationDiv .locations .activeLocation {
  padding: 0.5rem;
  background-color: #fff200;
}

.aboutPage .galery {
  padding: 0 2.8rem;
  min-height: 300px;
}

#logo2 {
  position: absolute;
  top: 64px;
  left: 42px;
  z-index: 3;
}

.ReactModalPortal .modalContent .modalHeader {
  width: 100%;
  height: 50px;
  text-align: right;
}

.ReactModalPortal .modalContent .modalBody {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
}

.modalFooter .iconDiv {
  cursor: pointer;
}

.modalBody img {
  width: 600px;
  height: 457px;
  object-fit: contain !important;
}

.searchPopup {
  z-index: 1023;
}

.searchPopup .searchField {
  margin-bottom: 0;
  z-index: 1023;
}

.exhibitionsContainer .exhibitionsList {
  grid-template-columns: 33% 33% 33%;
  justify-content: start;
  gap: 1rem;
}

.exhibitionsContainer .exhibitionsList .searchCard,
.exhibitionsContainer .exhibitionsList .searchCard .image,
.exhibitionsContainer .exhibitionsList .searchCard .image img {
  width: 100%;
}

.header {
  z-index: 1012;
}

.header a {
  font-family: "Apercu Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: #888888;
  text-decoration: none;
  cursor: pointer;
}

.header ul .dropdownContent {
  z-index: 1012;
}

.subMenuDiv {
  z-index: 1011;
  position: sticky;
  top: 76px;

  width: 100%;
  background-color: #fff;
}

.subMenuDiv .subMenu {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 8px;
  /* margin-left: 8%; */
}

.subMenuDiv .subMenu li {
  display: inline-block;
  margin-left: 42px;
}

.subMenuDiv .subMenu li a {
  font-family: "Apercu Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  text-decoration: none;
}

.searchPage .artistResults .artistsList {
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: start;
  gap: 1rem;
}

.searchPage .artistResults .artistsList .artistsCard img {
  width: 100%;
  object-fit: cover;
  height: 389px;
}

.my-masonry-grid_column img {
  width: 100%;
}

#biography {
  min-height: 300px;
}

.searchPopup .searchResultField .resultInfo a {
  cursor: pointer;
}

.exhibitionsContainer > div {
  padding: 0 8%;
}

.exhibitionsContainer .exhibitionCard p {
  line-height: 120%;
}

.bannerContainer .rightSide {
  padding: 0 16px;
}

.artistsContainer .artists .my-masonry-grid_column .artistName {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #25282e;
  background-color: #fff;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 10px;
}

.artistsContainer .artists .my-masonry-grid_column img {
  width: 100%;
  transition: all 0.5s ease;
  filter: grayscale();
}

.artistsContainer .artists .my-masonry-grid_column .artistCard {
  position: relative;
  cursor: pointer;
}

.artistsContainer .artists .my-masonry-grid_column .artistCard:hover img {
  opacity: 0.4;
}

.artistsContainer
  .artists
  .my-masonry-grid_column
  .artistCard:hover
  .artistName {
  display: block;
}

.pictureField a {
  cursor: pointer;
  width: fit-content;
}

.header ul li .disabled,
.header ul li .disabled:hover {
  opacity: 0.2 !important;
}

.pressContainer {
  padding: 0 11% 74px;
}

.searchSkeleton {
  margin: 5rem 3rem 5rem 0rem;
  display: flex;
  justify-content: start;
}

.searchSkeleton > div:first-child {
  margin-right: 1rem;
}

.newsContainer .newsHeader .newsRightSide {
  justify-content: end;
}

.publicationCard .imgDiv img {
  object-fit: contain;
  width: 100%;
}

.publicationCard h3,
.publicationCard p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.publicationDetail {
  height: auto;
}

.news-card:hover {
  background: #fff200;
}

.news-card:hover,
.news-card {
  transition: all 0.3s ease-in-out;
}

.news-card {
  padding: 20px;
  background: hsl(0, 0%, 98%);
}

@media (max-width: 600px) {
  .footer {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* @media (max-width: 500px) {
  .swiper-slide {
    width: 100% !important;
  }
} */

/* .swiper-horizontal {
  width: 100%;
} */

.fairsPageContainer {
  padding: 2rem 1rem;
}

.fairsContainer {
  margin: 2rem 1rem;
}

.fairsPageContainer > div {
  padding: 2rem 8%;
}

.fairsPageContainer a {
  cursor: pointer;
}

.fairsPageContainer .fairs {
  grid-template-columns: repeat(auto-fill, 370px);
}

.fairsPageContainer .fairs .fairCard {
  width: 370px;
}

.fairsPageContainer .active {
  font-weight: bold !important;
}

.fairsPageContainer .head h2 {
  margin-left: 46%;
}

.fairsPageContainer .head div:first-child {
  width: 100%;
}

.fairsPageContainer .head div:last-child {
  width: 32%;
  flex: unset;
}

.fairsContainer .fairs .fairCard,
.fairsContainer .rightSide {
  width: 100%;
}

.fairsContainer h2 {
  margin-bottom: 2rem;
  text-align: start !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 120%;
}

.fairsDetailContainer h1 {
  color: #25282e;
}

.fairsDetailContainer h1,
.fairsDetailContainer h2 {
  font-family: "Apercu Pro";
  font-weight: 700;
  font-size: 27px;
  line-height: 140%;
  margin-bottom: 10px;
}

.fairsDetailContainer h2 {
  color: "white";
}

.fairsContainer img {
  object-fit: contain;
}

/* .fairsContainer img{
  object-fit : cover;
} */

.exhibitionsContainer {
  padding: 4rem 66px;
}

/* .header ul li {
  margin-left: 40px;
} */

.artistlistskeleton {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

.publicationCard {
  overflow: hidden;
}

.fairCard a {
  cursor: pointer;
}

.newslistskeleton {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

.skeletonPublicationComponent {
  text-align: -webkit-center;
}

.skeletonArtisDetailLeft {
  width: 30%;
}

.skeletonArtisDetailRight {
  width: 50%;
}

.shopPubsCard .info h4 {
  overflow-wrap: break-word;
}

.shopPage .shopPubsField .shopPubs .shopPubsCard .imgDiv img {
  object-fit: contain;
  height: 300px;
}

.shopPage .shopDetailBanner .leftSide img {
  object-fit: contain;
  width: 100%;
}

.shopDetailsPage {
  position: relative;
  top: 76px;
}

.shopPage .shopSlider .product .leftSide .names .productName.active {
  height: auto;
  margin-bottom: 1rem;
}

.shoppingCartPage .shoppingCart .leftSide .product .imgDiv {
  width: 250px;
  height: 200px;
}

.shoppingCartPage .shoppingCart .leftSide .product .imgDiv img {
  width: 100%;
}

.shopPage .shopBanner .leftSide img {
  width: 100%;
  object-fit: contain;
}

.shopPage .shopBanner .rightSide h2 {
  overflow-wrap: break-word;
}

.publicationsContainer .publications .publicationCard .imgDiv img {
  object-fit: cover;
}

@media screen and (max-width: 1100px) {
  .fairsPageContainer .head h2 {
    margin-left: 0%;
  }
  .mySearch {
    margin-top: -50px;
  }
  .navbarRight ul {
    display: none;
  }
}

.navbarRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.headerGrid {
  justify-content: space-between;
}

.headerGrid,
.headerGrid2 {
  display: flex;
  flex-direction: row;
}

.locationImg {
  width: 100%;
}

.appFlex {
  display: flex;
  flex-direction: column;
}

/*News*/

.newsHead {
  display: flex;
  justify-content: space-between;
  font-family: "Apercu Pro", sans-serif;
  /* padding: 2rem 1rem; */
  margin: 2rem 0;
}

.newsHead h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  color: #25282e;
}

.newsHead .buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .exhibitionsContainer > div {
    padding: 0;
  }
  .exhibitionsContainer .exhibitions {
    grid-template-columns: repeat(auto-fill, 400px);
  }

  .footer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .newsHead {
    flex-direction: column;
    gap: 20px;
  }

  .exhibitionsContainer > div {
    padding: 0;
  }
  .exhibitionsContainer .exhibitions {
    grid-template-columns: repeat(auto-fill, 340px);
  }

  .exhibitionsContainer .buttons {
    overflow: scroll;
  }
}

@media screen and (min-width: 1024px) {
  .exhibitionsContainer > div {
    padding: 0;
  }
  .exhibitionsContainer .exhibitions {
    grid-template-columns: repeat(auto-fill, 460px);
  }
}

@media screen and (max-width: 992px) {
  .buttons > * {
    margin: 0;
  }
}

/* .CookieConsent {
  height: 6rem;
  padding: 2rem;
} */

.jobOpportunitiesPage {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 2rem 11%;
  gap: 20px;
  font-family: "Apercu Pro";
}

.jobOpportunitiesPage h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #25282e;
  margin-bottom: 24px;
}

.jobOpportunitiesPage p {
  font-weight: 400;
  font-size: 16px;
  line-height: 141%;
  color: #25282e;
}

.newsArrow {
  font-size: xx-large;
  height: 53px;
  width: 53px;
  padding: 8.83333px;
  color: #fff;
}

.newsArrow:hover {
  background-color: #fff200 !important;
  color: #000000;
}

.newsArrow.disabled:hover {
  background-color: transparent !important;
  cursor: unset !important;
}

.newsArrow.disabled {
  color: #393939 !important;
}

.fairsHeader {
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  padding: 30px 50px;
}

.fairsHeader,
.fairsRightSide {
  display: flex;
  align-items: center;
}

.fairsRightSide {
  flex: 1;
  justify-content: end;
}

.fairsArrow {
  font-size: xx-large;
  height: 53px;
  width: 53px;
  padding: 8.83333px;
  color: #000000;
}

.fairsArrow:hover {
  background-color: #fff200 !important;
  color: #000000;
}

.fairsArrow.disabled:hover {
  background-color: transparent !important;
  cursor: unset !important;
}

.fairsArrow.disabled {
  color: #999999 !important;
}
